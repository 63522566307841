import BigNumber from 'bignumber.js'

export function numberWithCommas (x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function format (value_, { divider = 1, toFixedNumber = undefined }) {
  let value
  if (!value_) {
    value = new BigNumber(0)
  } else {
    value = value_ instanceof BigNumber ? value_ : new BigNumber(value_)
  }
  return numberWithCommas(value.dividedBy(divider).toFixed(toFixedNumber, 1).toString())
}
