import VueSlider from 'vue-slider-component'
import defaultSliderOptions from './sliderOptions'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'tw-slider',
  components: {
    VueSlider,
  },
  props: {
    value: Number,
  },
  computed: {
    sliderOptions () {
      return {
        ...defaultSliderOptions,
        ...this.$attrs,
      }
    },
  },
}
