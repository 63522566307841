export default {
  name: 'tw-switch',
  emits: ['update:modelValue'],
  props: {
    value: Boolean,
    disabled: Boolean,
    off: Boolean,
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue)
        document.activeElement.blur()
      }
    },
  },
}
