import BigNumber from 'bignumber.js'

export const NULL_LOCK = '0'
export const HOURS1 = '1'
export const DAYS30 = '2'
export const DAYS180 = '3'
export const DAYS365 = '4'
export const DAYS730 = '5'

export const LOCK_PERIOD_NAMES = {
  [HOURS1]: '1 hour',
  [DAYS30]: '1 month',
  [DAYS180]: '6 months',
  [DAYS365]: '1 year',
  [DAYS730]: '2 years',
}

export const LOCK_MULTIPLIERS = {
  [HOURS1]: {
    text: 'x1',
    value: 1,
  },
  [DAYS30]: {
    text: 'x1.2',
    value: 1.2,
  },
  [DAYS180]: {
    text: 'x1.3',
    value: 1.3,
  },
  [DAYS365]: {
    text: 'x1.5',
    value: 1.5,
  },
  [DAYS730]: {
    text: 'x2',
    value: 2,
  },
}

export const MAX_AMOUNT_APPROVE = new BigNumber(2).pow(96).dividedBy(2).minus(1).toFixed(0)
