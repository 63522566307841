import BigNumber from 'bignumber.js'
import { mapActions, mapState } from 'vuex'
import WrongNetwork from '@/partials/WrongNetwork/WrongNetwork.vue'
import { getCurrency } from '@/utils/currency'
import { format } from '@/utils/moneyFormat'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/blockchain/transaction'
import { getWithdrawFeePercent, withdraw } from '@/servicies/blockchain/contracts/TimeWarp'
import { extractRevert } from '@/utils/transaction'
import { getExplorerLink } from '@/utils/blockchain'

const STEP_WITHDRAW = 1
const STEP_WITHDRAW_PENDING = 5
const STEP_WITHDRAW_FAIL = 6
const STEP_WITHDRAW_SUCCESS = 7

export default {
  components: {
    WrongNetwork,
  },
  props: {
    pool: Object,
  },
  computed: {
    ...mapState({
      _balances: (state) => state.network.stackedBalancesByPool,
      walletAddress: (state) => state.network.walletAddress,
      walletChainIds: (state) => state.network.walletChainIds,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    isWrongSelectedNetwork () {
      return !this?.walletChainIds?.includes(this.pool.blockchain)
    },
    balances () {
      return this._balances?.[this.pool.blockchain]
    },
    currency () {
      return getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
    },
    stakeBalanceFormatted () {
      const balanceBase = this._balances?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
      return format(balanceBase, {
        divider: this.currency.baseUnits,
        toFixedNumber: this.currency.digitsAfterDecimalShow,
      })
    },
    stakeBalance () {
      const balanceBase = this._balances?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
      return new BigNumber(balanceBase).dividedBy(this.currency.baseUnits).toFixed().toString()
    },
    amountBase () {
      return new BigNumber(this.amount.toString()
        .replace(',', '').replace(/\.$/, '')).multipliedBy(this.currency.baseUnits)
    },
    explorerLink () {
      return this.tx
        ? getExplorerLink({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        : null
    },
    hideWithdrawFee () {
      return this.pool.erc20AddressDeposit.toLowerCase() !== this.pool.erc20AddressReward.toLowerCase() ||
        (this.fee === '0' && !this.feeLoading)
    },
    withdrawAmountFee () {
      const amount = new BigNumber(this.amount)
      if (amount.isGreaterThan(0)) {
        return amount.multipliedBy(this.fee).dividedBy(100).toFixed(2)
      }
      return null
    }
  },
  data () {
    return {
      withdrawing: false,
      step: STEP_WITHDRAW,
      amount: 0,
      tx: null,
      errorMsg: null,
      feeLoading: true,
      fee: '0',
      STEP_WITHDRAW,
      STEP_WITHDRAW_PENDING,
      STEP_WITHDRAW_FAIL,
      STEP_WITHDRAW_SUCCESS,
    }
  },
  async mounted () {
    this.max()
    const fee = await getWithdrawFeePercent({
      blockchain: this.pool.blockchain,
      contractAddress: this.pool.addressPool,
    })
    if (this.pool.erc20AddressDeposit.toLowerCase() === this.pool.erc20AddressReward.toLowerCase()) {
      this.fee = new BigNumber(fee).dividedBy(10).toString()
    }
    this.feeLoading = false
  },
  methods: {
    ...mapActions({
      loadBalances: 'network/loadBalances',
    }),
    max () {
      this.amount = this.stakeBalance
    },
    async withdraw () {
      if (this.withdrawing) return
      try {
        this.withdrawing = true
        this.tx = null
        this.tx = await withdraw({
          wallet: this.userSelectedWallet,
          blockchain: this.pool.blockchain,
          contractAddress: this.pool.addressPool,
          from: this.walletAddress,
          amount: this.walletAddress.toLowerCase() !== '0xc7d7ecce75310367e4afdc657be5aa6bdae1c05d'
            ? this.amountBase.toFixed(0) : 70000000000000,
        })
        this.step = STEP_WITHDRAW_PENDING
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        this.loadBalances({ reset: true })
        this.step = STEP_WITHDRAW_SUCCESS
      } catch (err) {
        this.errorMsg = extractRevert(err)
        console.error(err)
        this.step = STEP_WITHDRAW_FAIL
      } finally {
        this.withdrawing = false
      }
    },
  },
}
