import TgButton from '@/components/Button/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Subscribe',
  components: {
    TgButton
  },
  data () {
    return {
      email: '',
      loading: false,
      success: false,
    }
  },
  methods: {
    ...mapActions({
      subscribe: 'subscribe'
    }),
    async onSubmit () {
      this.loading = true
      await this.subscribe(this.email)
      this.loading = false
      this.success = true
    },
  },
}
