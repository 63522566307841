import EventEmitter from 'events'
import { BLOCKCHAIN_BINANCE } from '@/constants/blockchain'
import { toChecksumAddress } from 'web3-utils'

const ethereum = (typeof window !== 'undefined') && window.ethereum

export const eventEmitter = new EventEmitter()

if (ethereum) {
  ethereum.autoRefreshOnNetworkChange = false

  ethereum.on('accountsChanged', async (accounts) => {
    if (accounts && Array.isArray(accounts) && accounts.length > 0) {
      eventEmitter.emit(
        'accountsChanged',
        accounts.map(item => toChecksumAddress(item)),
      )
    }
    if (accounts && Array.isArray(accounts) && accounts.length === 0) {
      eventEmitter.emit('disconnect')
    }
  })

  ethereum.on('chainChanged', (chainId) => {
    if (chainId) {
      eventEmitter.emit('chainChanged', parseInt(chainId, 16))
    }
  })

  ethereum.on('connect', () => {
    eventEmitter.emit('connect')
  })

  ethereum.on('disconnect', () => {
    eventEmitter.emit('disconnect')
  })
}

export function getProvider () {
  return ethereum
}

export function isMetamaskInstalled () {
  return !!ethereum// && ethereum.isMetaMask
}

export async function getAddress () {
  if (isMetamaskInstalled()) {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
    return toChecksumAddress(accounts[0])
  } else {
    return null
  }
}

export async function getChainId () {
  if (isMetamaskInstalled()) {
    const chainId = await ethereum.request({ method: 'net_version' })
    return parseInt(chainId)
  } else {
    return null
  }
}

export async function addChain (blockchain) {
  // TODO get code from time bridge
  if (blockchain !== BLOCKCHAIN_BINANCE) return
  const prefixChainName = process.env.NODE_ENV === 'development' ? ' Testnet' : ''
  const params = [{
    chainId: '0x' + blockchain.toString(16),
    chainName: 'BNB Chain' + prefixChainName,
    nativeCurrency: {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [process.env.VUE_APP_WEB3_PROVIDER_BINANCE],
    blockExplorerUrls: [process.env.VUE_APP_EXPLORER_BINANCE],
  }]
  await ethereum.request({
    method: 'wallet_addEthereumChain',
    params,
  })
}
