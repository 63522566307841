<tw-modal class="root stake">
  <wrong-network v-if="isWrongSelectedNetwork" :required-blockchain="pool.blockchain" />
  <template v-else>
    <template v-if="step === STEP_WITHDRAW">
      <h3 class="modal-title">Withdraw</h3>
      <form @submit.prevent="withdraw">
        <div class="balance">
          <div class="info">
            <span class="label">Staked Balance:</span> <span class="value">{{ stakeBalanceFormatted }} {{currency.name}}</span>
          </div>
          <button class="max-btn" type="button" @click="max">Max</button>
        </div>

        <div v-if="!hideWithdrawFee" class="fees">
          <div class="info">
            <span class="label">Withdrawal fee: </span>
            <tw-loader v-if="feeLoading" class="loader" />
            <template v-else>
              <span class="value">{{fee}}%</span>
              <span class="fee" v-if="withdrawAmountFee"> ({{withdrawAmountFee}} TIME)</span>
              <tw-icon class="icon" icon="info" v-popover="'Withdrawal fee will be distributed <br /> among current holders'" />
            </template>
          </div>
        </div>
        <tw-numeric-input-2 min="0.000000000000000001" :max="stakeBalance" class="amount-input" label="Withdrawal Amount" v-model="amount"></tw-numeric-input-2>

        <tw-button class="stake-btn" type="submit" :loading="withdrawing" :disabled="feeLoading">WITHDRAW</tw-button>
      </form>
    </template>
    <div
      v-if="[
      STEP_WITHDRAW_PENDING,
      STEP_WITHDRAW_FAIL,
      STEP_WITHDRAW_SUCCESS,
      ].includes(step)"
      class="tx-status"
    >
      <tw-process-state v-if="STEP_WITHDRAW_PENDING === step" />
      <tw-process-state v-if="STEP_WITHDRAW_SUCCESS === step" success />
      <tw-process-state v-if="STEP_WITHDRAW_FAIL === step" error />

      <div class="process-header">
        <template v-if="STEP_WITHDRAW_PENDING === step">
          Withdrawing...
        </template>
        <template v-if="STEP_WITHDRAW_SUCCESS === step">
          Success
        </template>
        <template v-if="STEP_WITHDRAW_FAIL === step">
          Error
        </template>
      </div>

      <div class="process-text">
        <template v-if="step === STEP_WITHDRAW_PENDING">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a>
          <template v-else>Transaction</template>
          successfully submitted to the blockchain, waiting to be confirmed
        </template>
        <template v-if="STEP_WITHDRAW_SUCCESS === step">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a> confirmed successfully
        </template>
        <template v-if="STEP_WITHDRAW_FAIL === step">
          <div v-if="errorMsg" class="error-msg">
            {{ errorMsg }}
          </div>
          <template v-else>
            Something went wrong. Please try again
          </template>
        </template>
      </div>
    </div>
  </template>
</tw-modal>
