export default {
  props: {
    circle: Boolean,
    fill: Boolean,
    duration: {
      type: Number,
      default: 1
    },
    delay: {
      type: Number,
      default: 0
    }
  }
}
