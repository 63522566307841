import Deferred from 'promise-deferred'
import { BLOCKCHAIN_ETHEREUM, EXPLORER_BY_BLOCKCHAIN } from '@/constants/blockchain'

export function getExplorerLink ({ blockchain = BLOCKCHAIN_ETHEREUM, tx }) {
  return `${EXPLORER_BY_BLOCKCHAIN[blockchain]}/tx/${tx}`
}

export function waitTxFromMetamask (eventEmitter) {
  const deferred = new Deferred()
  eventEmitter
    .on('transactionHash', (hash) => {
      deferred.resolve(hash)
    })
    .on('error', (err) => {
      console.error(err)
      deferred.reject(err)
    })
  return deferred.promise
}
