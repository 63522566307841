import BigNumber from 'bignumber.js'
import { mapActions, mapState } from 'vuex'
import WrongNetwork from '@/partials/WrongNetwork/WrongNetwork.vue'
import { getCurrency } from '@/utils/currency'
// import { extractRevert } from '@/utils/transaction'
import { format } from '@/utils/moneyFormat'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/blockchain/transaction'
import { compound } from '@/servicies/blockchain/contracts/TimeWarp'
import { extractRevert } from '@/utils/transaction'
import { getExplorerLink } from '@/utils/blockchain'

const STEP_COMPOUND = 1
const STEP_COMPOUND_PENDING = 5
const STEP_COMPOUND_FAIL = 6
const STEP_COMPOUND_SUCCESS = 7

export default {
  components: {
    WrongNetwork,
  },
  props: {
    pool: Object,
  },
  computed: {
    ...mapState({
      balances: (state) => state.network.rewardBalanceByPool,
      walletAddress: (state) => state.network.walletAddress,
      walletChainIds: (state) => state.network.walletChainIds,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    isWrongSelectedNetwork () {
      return !this?.walletChainIds?.includes(this.pool.blockchain)
    },
    currency () {
      return getCurrency({
        address: this.pool.erc20AddressReward,
        blockchain: this.pool.blockchain,
      })
    },
    rewardBalanceFormatted () {
      const balanceBase = this.balances?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
      return format(balanceBase, {
        divider: this.currency.baseUnits,
        // toFixedNumber: this.currency.digitsAfterDecimalShow,
      })
    },
    amountBase () {
      return new BigNumber(this.amount).multipliedBy(this.currency.baseUnits)
    },
    approveActive () {
      return this.amountBase.isGreaterThan(this.allowance) || this.allowance === '0'
    },
    explorerLink () {
      return this.tx
        ? getExplorerLink({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        : null
    },
  },
  data () {
    return {
      compounding: false,
      step: STEP_COMPOUND,
      amount: 0,
      tx: null,
      errorMsg: null,
      STEP_COMPOUND,
      STEP_COMPOUND_PENDING,
      STEP_COMPOUND_FAIL,
      STEP_COMPOUND_SUCCESS,
    }
  },
  methods: {
    ...mapActions({
      loadBalances: 'network/loadBalances'
    }),
    async compound () {
      if (this.compounding) return
      try {
        this.compounding = true
        this.tx = null
        this.tx = await compound({
          wallet: this.userSelectedWallet,
          blockchain: this.pool.blockchain,
          contractAddress: this.pool.addressPool,
          from: this.walletAddress,
        })
        this.step = STEP_COMPOUND_PENDING
        await awaitTransactionStatusAppliedBlockchain({ blockchain: this.pool.blockchain, tx: this.tx })
        this.loadBalances({ reset: true })
        this.step = STEP_COMPOUND_SUCCESS
      } catch (err) {
        this.errorMsg = extractRevert(err)
        console.error(err)
        this.step = STEP_COMPOUND_FAIL
      } finally {
        this.compounding = false
      }
    },
  },
}
