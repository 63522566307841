export default {
  name: 'tw-numeric-input-2',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: '0',
      required: true
    },
    max: {
      type: [String, Number],
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false,
    },
    min: {
      type: [String, Number],
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false
    },
    readOnly: Boolean,
    disabled: Boolean,
  },
  data () {
    return {
      amount: ''
    }
  },
  mounted () {
    this.amount = this.modelValue
  },
  watch: {
    modelValue (newValue) {
      this.amount = newValue
    },
  },
  methods: {
    onInputChange () {
      this.$emit('update:modelValue', this.amount)
    },
  }
}
