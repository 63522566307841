<tw-modal class="root stake">
  <wrong-network v-if="isWrongSelectedNetwork" :required-blockchain="pool.blockchain" />
  <template v-else>
    <template v-if="step === STEP_STAKE">
      <h3 class="modal-title">Deposit</h3>
      <form @submit.prevent="stake">
        <div class="balance">
          <div class="info">
            <span class="label">Wallet Balance:</span> <span class="value">{{ stakeBalanceFormatted }} {{currency.name}}</span>
          </div>
          <button class="max-btn" type="button" @click="max">Max</button>
        </div>
        <tw-numeric-input-2 min="0.000000000000000001" :max="stakeBalance" class="amount-input" label="Deposit Amount" v-model="amount"></tw-numeric-input-2>
<!--        <div class="lock-select">-->
<!--          <tw-numeric-input-->
<!--            class="lock-input input"-->
<!--            label="Locking Type"-->
<!--            :postfix="lockingTypePostfix"-->
<!--            :precision="0"-->
<!--            :modelValue="lockingTypeValue"-->
<!--            disabled-->
<!--          ></tw-numeric-input>-->
<!--          <tw-slider class="slider" v-model="lockType" :vData="lockPeriods" />-->
<!--        </div>-->
<!--        <div class="info multiplier">-->
<!--          <span class="label">Multiplier:</span> <span class="value">{{ LOCK_MULTIPLIERS?.[lockType]?.text }}</span>-->
<!--        </div>-->

<!--        <div v-if="+rewardBalance" class="info reward">-->
<!--          <span class="label">Available Reward:</span> <span class="value">{{ rewardBalanceFormatted }} TIME</span>-->
<!--        </div>-->

<!--        <div v-if="hasValidationError" class="error">-->
<!--          Locking type cannot be reduced-->
<!--        </div>-->

        <tw-button v-if="approveActive" class="stake-btn" type="submit" :disabled="hasValidationError" :loading="!init || approving">APPROVE</tw-button>
        <template v-else>
          <template v-if="+rewardBalance">
            <tw-button v-if="!isLpPool" class="stake-btn" @click="stakeAndCompound" type="submit" :disabled="hasValidationError" :loading="!init || staking">DEPOSIT & COMPOUND</tw-button>
            <tw-button class="stake-btn" @click="stakeAndHarvest" type="submit" :disabled="hasValidationError" :loading="!init || staking">DEPOSIT & HARVEST</tw-button>
          </template>
          <tw-button v-else class="stake-btn" type="submit" :disabled="hasValidationError" :loading="!init || staking">DEPOSIT</tw-button>
        </template>
      </form>
    </template>
    <div
      v-if="[
      STEP_APPROVE_PENDING,
      STEP_APPROVE_SUCCESS,
      STEP_APPROVE_FAIL,
      STEP_STAKE_PENDING,
      STEP_STAKE_SUCCESS,
      STEP_STAKE_FAIL
      ].includes(step)"
      class="tx-status"
    >
      <tw-process-state v-if="[STEP_APPROVE_PENDING, STEP_STAKE_PENDING].includes(step)" />
      <tw-process-state v-if="[STEP_APPROVE_SUCCESS, STEP_STAKE_SUCCESS].includes(step)" success />
      <tw-process-state v-if="[STEP_APPROVE_FAIL, STEP_STAKE_FAIL].includes(step)" error />

      <div class="process-header">
        <template v-if="step === STEP_APPROVE_PENDING">
          Approving...
        </template>
        <template v-if="step === STEP_STAKE_PENDING">
          Depositing...
        </template>
        <template v-if="[STEP_APPROVE_SUCCESS, STEP_STAKE_SUCCESS].includes(step)">
          Success
        </template>
        <template v-if="[STEP_APPROVE_FAIL, STEP_STAKE_FAIL].includes(step)">
          Error
        </template>
      </div>

      <div class="process-text">
        <template v-if="[STEP_APPROVE_PENDING, STEP_STAKE_PENDING].includes(step)">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a>
          <template v-else>Transaction</template>
          successfully submitted to the blockchain, waiting to be confirmed
        </template>
        <template v-if="step === STEP_APPROVE_SUCCESS">
          Successfully approved
        </template>
        <template v-if="step === STEP_STAKE_SUCCESS">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a> confirmed successfully
        </template>
        <template v-if="[STEP_APPROVE_FAIL, STEP_STAKE_FAIL].includes(step)">
          <div v-if="errorMsg" class="error-msg">
            {{ errorMsg }}
          </div>
          <template v-else>
            Something went wrong. Please try again
          </template>
        </template>
      </div>
    </div>
  </template>
</tw-modal>
