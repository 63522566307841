import { mapActions, mapState } from 'vuex'
import { numberWithCommas } from '@/utils/moneyFormat'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import { sendGaEvent } from '@/utils/analytics'

export default {
  name: 'Time',
  components: {
    Skeleton,
  },
  mounted () {
    this.loadRate()
  },
  computed: {
    ...mapState({
      timePrice: state => state.timePrice
    }),
    loading () {
      return this.timePrice === null
    },
    price () {
      if (this.timePrice === null) {
        return '00.00'
      }
      return numberWithCommas(parseFloat(this.timePrice).toFixed(2))
    },
    marketCap () {
      if (this.timePrice === null) {
        return '00,000,000'
      }
      return numberWithCommas(Math.round(this.timePrice * 710113).toString())
    }
  },
  methods: {
    ...mapActions({
      loadRate: 'loadRate'
    }),
    onExchangeClick () {
      sendGaEvent('exchange_open')
    },
  }
}
