import Joi from 'joi'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_POLYGON
} from '@/constants/blockchain'
import AbstractModel from './AbstractModel'

class Currency extends AbstractModel {
  static propTypes = {
    name: Joi.string(),
    secondName: Joi.string(),
    blockchain: Joi.number().valid(
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      BLOCKCHAIN_POLYGON
    ),
    address: Joi.string().default('0x0000000000000000000000000000000000000000'),
    decimals: Joi.number().default(18),
    digitsAfterDecimalShow: Joi.number().default(2),
    digitsAfterDecimalShowInTvl: Joi.number().default(0),
    getLink: Joi.string().default('https://app.uniswap.org/#/swap'),
  }

  get isBaseCurrency () {
    return (this.name === 'ETH' && this.blockchain === BLOCKCHAIN_ETHEREUM) ||
      (this.name === 'BNB' && this.blockchain === BLOCKCHAIN_BINANCE)
  }

  get baseUnits () {
    return Math.pow(10, this.decimals)
  }

  static fromEnv (item) {
    return new Currency({
      ...item,
      secondName: item.secondName || item.name,
    })
  }
}

export default Currency
