export default {
  name: 'FAQ',
  data () {
    return {
      showed: [],
    }
  },
  methods: {
    onClick (i) {
      const index = this.showed.indexOf(i)
      if (index === -1) {
        this.showed.push(i)
      } else {
        this.showed.splice(index, 1)
      }
    }
    ,
  },
}
