<div
  class="root-skeleton"
  :class="{circle, fill}"
  :style="{
    '--animation-duration': `${duration}s`,
    '--animation-delay': `${delay}s`
  }"
>
  <slot></slot>
</div>
