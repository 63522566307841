import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home/Home.vue'
import Pools from '@/pages/Pools/Pools.vue'
import PoolsV2 from '@/pages/PoolsV2/PoolsV2.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pools',
    name: 'Pools',
    component: Pools
  },
  {
    path: '/mdao',
    name: 'MDAO Pool',
    component: PoolsV2
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
