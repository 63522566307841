<div class="subscribe-wrap">
  <div class="container subscribe-container">
    <div class="header">
      <div>Sign up</div>
      <div>for news</div>
    </div>
    <div class="content">
      <img src="/static/images/landing/man.svg" alt="Subscribe" />
      <div class="text">
        <div class="description">
          Users start accruing rewards as soon as they lock their tokens in TimeWarp.<br>
          More information on the latest developments and updates, subscribe to our newsletter.
        </div>
        <div class="label">
          <template v-if="success">You have been successfully subscribed to notifications</template>
          <template v-else>Subscribe for notifications</template>
        </div>
        <form :class="{ hidden: success }" @submit.prevent="onSubmit">
          <input v-model="email" type="email" required placeholder="Your Email">
          <tg-button type="submit" class="btn" :loading="loading" :disabled="loading">Submit</tg-button>
        </form>
      </div>
    </div>
  </div>
</div>
