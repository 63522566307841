import Joi from 'joi'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_POLYGON
} from '@/constants/blockchain'
import AbstractModel from './AbstractModel'

class Pool extends AbstractModel {
  static propTypes = {
    name: Joi.string(),
    blockchain: Joi.number().valid(
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      BLOCKCHAIN_POLYGON,
    ),
    address: Joi.string(),
    unlockAll: Joi.bool(),
    erc20AddressDeposit: Joi.string(),
    erc20AddressReward: Joi.string(),
    icon: Joi.string(),
    lpAsset0Address: Joi.string(), // only for lp pools
    lpAsset1Address: Joi.string(), // only for lp pools
  }

  get isLpPool () {
    return this.erc20AddressDeposit.toLowerCase() !== this.erc20AddressReward.toLowerCase()
  }
}

export default Pool
