<tw-modal class="root walletconnect-qr">
  <template v-if="!error">
    <h3 class="modal-title">Select network</h3>
    <section class="section networks-section">
      <h4 class="section-title">
        Open your camera app or mobile wallet and scan the code to connect
      </h4>
      <qrcode-vue
          class="qr-code"
          :value="uri"
          :size="450"
      />
      <div class="copy-btn-block">
        <tw-button @click="copyLink" :disabled="!uri" class="button copy-btn" :class="{ active: open }">
          <div
              v-if="uri"
              class="icon-btn"
          >
            {{ copyMessage }}
          </div>
          <template v-else>Wait</template>
        </tw-button>
      </div>
    </section>
  </template>
  <template v-else>
    <div class="walletconnect-error">
      <tw-process-state error/>
      <div class="process-header">Error</div>
      <div class="process-text">{{ error }}</div>
    </div>
  </template>
</tw-modal>
