<tw-modal class="root stake">
  <wrong-network v-if="isWrongSelectedNetwork" :required-blockchain="pool.blockchain" />
  <template v-else>
    <template v-if="step === STEP_COMPOUND">
      <h3 class="modal-title">Compound</h3>
      <form @submit.prevent="()=>{}">
        <div class="info">
          <span class="label">Available Reward:</span> <span class="value">{{ rewardBalanceFormatted }} TIME</span>
        </div>
        <tw-button class="stake-btn" @click="compound" :loading="compounding">COMPOUND</tw-button>
      </form>
    </template>
    <div
      v-if="[
      STEP_COMPOUND_PENDING,
      STEP_COMPOUND_FAIL,
      STEP_COMPOUND_SUCCESS,
      ].includes(step)"
      class="tx-status"
    >
      <tw-process-state v-if="STEP_COMPOUND_PENDING === step" />
      <tw-process-state v-if="STEP_COMPOUND_SUCCESS === step" success />
      <tw-process-state v-if="STEP_COMPOUND_FAIL === step" error />

      <div class="process-header">
        <template v-if="STEP_COMPOUND_PENDING === step">
          Compounding...
        </template>
        <template v-if="STEP_COMPOUND_SUCCESS === step">
          Success
        </template>
        <template v-if="STEP_COMPOUND_FAIL === step">
          Error
        </template>
      </div>

      <div class="process-text">
        <template v-if="step === STEP_COMPOUND_PENDING">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a>
          <template v-else>Transaction</template>
          successfully submitted to the blockchain, waiting to be confirmed
        </template>
        <template v-if="STEP_COMPOUND_SUCCESS === step">
          <a v-if="explorerLink" class="tx-link" :href="explorerLink" target="_blank">Transaction</a> confirmed successfully
        </template>
        <template v-if="STEP_COMPOUND_FAIL === step">
          <div v-if="errorMsg" class="error-msg">
            {{ errorMsg }}
          </div>
          <template v-else>
            Something went wrong. Please try again
          </template>
        </template>
      </div>
    </div>
  </template>
</tw-modal>
