import EventEmitter from 'events'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_POLYGON, PROVIDER_BY_CHAIN_ID,
  WALLETCONNECT_PROJECT_ID,
  WALLETCONNECT_RELAY_URL,
  WALLETCONNECT_SITE_DESCRIPTION,
  WALLETCONNECT_SITE_NAME
} from '@/constants/blockchain'

let provider = null
let session = null

export const eventEmitter = new EventEmitter()

export function resetProvider () {
  resetSession({ topic: provider?.session })
  provider = null
  eventEmitter.removeAllListeners()
}

export function resetSession ({ topic, hardReset = false }) {
  if ((session && session.topic === topic) || hardReset) {
    session = null
  }
}

export async function getProviderAsync (newInit = false) {
  if (!provider || newInit) {
    resetProvider()
    const UniversalProvider = (
      await import(/* webpackChunkName: "@walletconnect/universal-provider" */ '@walletconnect/universal-provider')
    ).default
    provider = await UniversalProvider.init({
      projectId: WALLETCONNECT_PROJECT_ID,
      // logger: 'debug',
      relayUrl: WALLETCONNECT_RELAY_URL,
      metadata: {
        name: WALLETCONNECT_SITE_NAME,
        description: WALLETCONNECT_SITE_DESCRIPTION,
        url: window.location.host,
        icons: [`${window.location.origin}/img/wc-bg.png`]
      }
    })
    window.provider = provider
    console.log('provider', provider)
    if (typeof provider === 'undefined') {
      throw new Error('WalletConnect is not initialized')
    }

    provider.on('display_uri', (uri) => {
      console.log('EVENT', 'QR Code Modal open')
      eventEmitter.emit('walletUri', uri)
    })

    // Subscribe to session ping
    provider.on('session_ping', ({ id, topic }) => {
      console.log('EVENT', 'session_ping')
      console.log(id, topic)
    })

    // Subscribe to connect
    provider.on('connect', (data) => {
      console.log('EVENT', 'connect', data)
    })

    // Subscribe to disconnect
    provider.on('disconnect', (data) => {
      console.log('EVENT', 'disconnect', data)
    })

    // Subscribe to session event
    provider.on('session_event', ({ event, chainId }) => {
      console.log('EVENT', 'session_event')
      console.log(event, chainId)

      // TODO accountsChanged chainChanged - could not find cases
      // provider.on('accountsChanged', async (accounts) => {
      //   if (accounts && Array.isArray(accounts) && accounts.length > 0) {
      //     const web3 = await getWeb3Async({})
      //     eventEmitter.emit(
      //       'accountsChanged',
      //       accounts.map(item => web3.utils.toChecksumAddress(item)),
      //     )
      //   }
      // })
      //
      // provider.on('chainChanged', (chainId) => {
      //   if (chainId) {
      //     eventEmitter.emit('chainChanged', parseInt(chainId))
      //   }
      // })
    })

    // Subscribe to session update
    provider.on(
      'session_update',
      ({ topic, session }) => {
        console.log('EVENT', 'session_updated')
        // setSession(session)
      },
    )

    // Subscribe to session delete
    provider.on('session_delete', ({ id, topic }) => {
      console.log('EVENT', 'session_deleted')
      resetSession({ topic })
      eventEmitter.emit('disconnect')
    })

    await _checkForPersistedSession()
    return provider
  } else {
    return provider
  }
}

export function getSession () {
  if (provider && session) {
    return session
  }

  return null
}

export async function sessionConnect (chainIds) {
  if (provider) {
    const namespaces = {
      eip155: {
        methods: [
          'eth_sendTransaction',
          'eth_signTransaction',
          'eth_sign',
          'personal_sign',
          'eth_signTypedData',
        ],
        chains: chainIds.map(network => `eip155:${network}`), // Example ['eip155:1', 'eip155:56', 'eip155:137']
        events: ['chainChanged', 'accountsChanged'],
        rpcMap: {
          [BLOCKCHAIN_ETHEREUM]: PROVIDER_BY_CHAIN_ID[BLOCKCHAIN_ETHEREUM],
          [BLOCKCHAIN_BINANCE]: PROVIDER_BY_CHAIN_ID[BLOCKCHAIN_BINANCE],
          [BLOCKCHAIN_POLYGON]: PROVIDER_BY_CHAIN_ID[BLOCKCHAIN_POLYGON]
        },
      },
    }
    session = await provider.connect({ namespaces })
    eventEmitter.emit('connect')
  }
}

// Populates (the last) existing session to state
export async function onSessionConnected (_session) {
  if (!provider) {
    throw new ReferenceError('WalletConnect Provider is not initialized')
  }
  const allNamespaceAccounts = Object.values(_session.namespaces)
    .map(namespace => namespace.accounts)
    .flat()
  console.log('Restored namespace accounts', allNamespaceAccounts)
  session = _session
}

export async function _checkForPersistedSession () {
  if (typeof provider === 'undefined') {
    throw new Error('WalletConnect is not initialized')
  }
  if (session) {
    return
  }
  if (provider?.session) {
    const _session = provider?.session
    await onSessionConnected(_session)
  }
}

export async function getChainsAndAddress () {
  if (!provider || !session) {
    return { address: null, chainIds: null }
  }
  const allNamespaceAccounts = Object.values(session.namespaces)
    .map(namespace => namespace.accounts)
    .flat()
  const listChainIds = Object.values(allNamespaceAccounts)
    .map(namespaceAccount => namespaceAccount.split(':'))
    .map(chainData => parseInt(chainData[1], 10))
  const chainData = allNamespaceAccounts[0].split(':')
  const web3 = await getWeb3Async({})
  return { address: web3.utils.toChecksumAddress(chainData[2]), listChainIds }
}
