<div class="wrong-network">
  <div class="tx-status">
    <tw-process-state error />
    <div class="process-header">
      Wrong network
    </div>
    <div class="process-text">
      Select correct network for {{ requiredBlockchainName }} blockchain
    </div>
  </div>
</div>
