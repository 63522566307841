import { CURRENCIES } from '@/constants/currencies'
import { BLOCKCHAIN_BINANCE } from '@/constants/blockchain'

export const getCurrency = ({ name, secondName, address, blockchain = BLOCKCHAIN_BINANCE }) => {
  return CURRENCIES
    .find((item) => (item.address === address || item.name === name || item.secondName === secondName) && item.blockchain === blockchain)
}

export const getBaseCurrency = (blockchain = BLOCKCHAIN_BINANCE) => {
  return CURRENCIES.find((item) => item.blockchain === blockchain && item.address === '0x0000000000000000000000000000000000000000')
}
