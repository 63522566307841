<div class="container projects-container">
  <div class="header">Chrono.tech projects</div>
  <div class="description">
    The Chrono.tech ecosystem consists of a suite of blockchain products and services. A proportion of the revenues
    generated by these will be used to buy back TIME and distribute it to users who stake their tokens
  </div>
  <div class="project lx">
    <div class="top">
      <a href="https://laborx.com" class="logo">
        <img src="/static/images/logo/lx.svg" alt="LaborX">
      </a>
      <div class="socials">
        <a href="https://twitter.com/LaborXNews" target="_blank"><img src="/static/images/logo/twitter.svg" alt="Twitter"></a>
        <a href="https://www.facebook.com/LaborXNews" target="_blank"><img src="/static/images/logo/facebook.svg" alt="Facebook"></a>
        <a href="https://www.linkedin.com/company/laborxnews" target="_blank">
          <img src="/static/images/logo/linkedin.svg" alt="LinkedIn">
        </a>
        <a href="https://t.me/laborx" target="_blank"><img src="/static/images/logo/telegram.svg" alt="Telegram"></a>
      </div>
    </div>
    <div class="medium">
      <div class="text col">
        Blockchain-based freelance jobs platform with digital work agreements and cryptocurrency payments
      </div>
      <div class="fees col">
        <div class="label">LaborX generates fees from:</div>
        <div class="fee first">
          <div class="value">10%</div>
          <div class="fee-text">from contract value</div>
        </div>
      </div>
    </div>
    <div class="stats">
      <div class="stat">
        <div class="content">
          <div class="name">Number of users</div>
          <div class="text">Average growth 30% per quarter</div>
          <tw-chart class="chart" type="line" :data="userChartData" :options="chartOptions" />
        </div>
      </div>
      <div class="stat">
        <div class="content">
          <div class="name">Number of Gigs</div>
          <div class="text">Average growth 40% per quarter</div>
          <tw-chart class="chart" type="line" :data="gigsChartData" :options="chartOptions" />
          <!-- <canvas class="chart" id="gigsChart"></canvas> -->
        </div>
      </div>
    </div>
  </div>

  <div class="project tx">
    <div class="top">
      <a href="https://timex.io" class="logo">
        <img src="/static/images/logo/timex-big.svg" alt="TimeX">
      </a>
      <div class="socials">
        <a href="https://twitter.com/ExchangeTimex" target="_blank"><img src="/static/images/logo/twitter.svg" alt="Twitter"></a>
        <a href="https://www.facebook.com/timex.io" target="_blank"><img src="/static/images/logo/facebook.svg" alt="Facebook"></a>
        <a href="https://www.linkedin.com/company/timex-io" target="_blank">
          <img src="/static/images/logo/linkedin.svg" alt="LinkedIn">
        </a>
        <a href="https://t.me/timexio" target="_blank"><img src="/static/images/logo/telegram.svg" alt="Telegram"></a>
      </div>
    </div>
    <div class="medium">
      <div class="text col">
        Plasma-based hybrid cryptocurrency exchange, combining the speed and privacy of a centralised system with
        the security and transparency of a decentralised solution
      </div>
      <div class="fees col">
        <div class="label">TimeX generates revenues from:</div>
        <div class="fee first">
          <div class="value">0.25%</div>
          <div class="fee-text">Maker Fee</div>
        </div>
        <div class="fee last">
          <div class="value">0.5%</div>
          <div class="fee-text">Taker Fee</div>
        </div>
      </div>
    </div>
    <div class="others">
      <div class="other-product">
        <div class="top-block">
          <a href="https://paymentx.io" class="logo">
            <img src="/static/images/logo/px.svg" alt="PaymentX">
          </a>
          <div class="socials">
            <a href="https://twitter.com/Payment_X" target="_blank"><img src="/static/images/logo/twitter.svg" alt="Twitter"></a>
            <a href="https://www.facebook.com/paymentx" target="_blank"><img src="/static/images/logo/facebook.svg" alt="Facebook"></a>
            <a href="https://www.linkedin.com/company/paymentx" target="_blank">
              <img src="/static/images/logo/linkedin.svg" alt="LinkedIn">
            </a>
            <a href="https://t.me/paymentx" target="_blank"><img src="/static/images/logo/telegram.svg" alt="Telegram"></a>
          </div>
        </div>
        <div class="bottom">
          <div class="text">Cryptocurrency payroll solution to automate businesses’ crypto payments for employees and contractors</div>
        </div>
      </div>
      <div class="other-product">
        <div class="top-block">
          <a href="https://audt.to" class="logo">
            <img src="/static/images/logo/audt.svg" alt="AUDT">
          </a>
          <div class="socials">
            <a href="https://twitter.com/audt_to" target="_blank"><img src="/static/images/logo/twitter.svg" alt="Twitter"></a>
            <a href="https://www.facebook.com/AUDToken" target="_blank"><img src="/static/images/logo/facebook.svg" alt="Facebook"></a>
            <a href="https://t.me/audt_to" target="_blank"><img src="/static/images/logo/telegram.svg" alt="Telegram"></a>
          </div>
        </div>
        <div class="bottom">
          <div class="text">Compliant stablecoin backed by AUD reserves</div>
          <div class="market-cap">
            <div class="value">$773,905,613</div>
            <div class="label">Market Cap</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
