import BigNumber from 'bignumber.js'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import { waitTxFromMetamask } from '@/utils/blockchain'
import { signAndSend } from '@/servicies/blockchain/transaction'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  WEB3_PROVIDER_METAMASK
} from '@/constants/blockchain'

const contractsMap = {
  [BLOCKCHAIN_BINANCE]: {},
  [BLOCKCHAIN_ETHEREUM]: {},
  [BLOCKCHAIN_POLYGON]: {}
}

export async function getContractAsync ({ blockchain, contractAddress }) {
  const web3 = await getWeb3Async({ blockchain })
  const artifacts = await import(/* webpackChunkName: 'timewarp-sc-artifacts' */ 'timewarp-sc-artifacts/artifacts/TimeWarpPool.json')
  if (!contractsMap[blockchain][contractAddress]) {
    contractsMap[blockchain][contractAddress] = new web3.eth.Contract(artifacts.abi, contractAddress)
  }
  return contractsMap[blockchain][contractAddress]
}

export async function deposit (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from,
    lockType,
    amount,
    compound
  }
) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const userLock = await contract.methods.userLock(from).call()
  if (parseInt(userLock) > 0) {
    const contractLastReward = parseInt(await contract.methods.lastReward().call())
    const userLastReward = parseInt(await contract.methods.userLastReward(from).call())
    if (contractLastReward - userLastReward >= 100) {
      throw new Error(
        'In order to deposit tokens, you must first make several harvest/compounds, since we cannot award rewards in one transaction'
      )
    }
  }
  const encodedAbi = contract.methods.deposit(lockType, amount, compound).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function withdraw (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from,
    amount
  }
) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const userLock = await contract.methods.userLock(from).call()
  if (parseInt(userLock) > 0) {
    const contractLastReward = parseInt(await contract.methods.lastReward().call())
    const userLastReward = parseInt(await contract.methods.userLastReward(from).call())
    if (contractLastReward - userLastReward >= 100) {
      throw new Error(
        'In order to withdraw tokens, you must first make several harvest/compounds, since we cannot award rewards in one transaction'
      )
    }
  }
  const encodedAbi = contract.methods.withdraw(amount).encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function compound (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from
  }
) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const encodedAbi = contract.methods.compound().encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function harvest (
  {
    wallet = WEB3_PROVIDER_METAMASK,
    blockchain,
    contractAddress,
    from
  }
) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const encodedAbi = contract.methods.harvest().encodeABI()
  const eventEmitter = await signAndSend({
    wallet,
    from,
    to: contractAddress,
    encodedAbi,
    blockchain
  })
  return waitTxFromMetamask(eventEmitter)
}

export async function totalStacked ({ blockchain, contractAddress, lockType }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.totalStacked(lockType).call()
  return value
}

export async function totalStakedInPool ({ blockchain, contractAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.totalStakedInPools().call()
  return value
}

export async function totalStakedInPoolWithMultipliers ({ blockchain, contractAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.stakedWithMultipliers().call()
  return value
}

export async function lastReward ({ blockchain, contractAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.lastReward().call()
  return value
}

export async function userLock ({ blockchain, contractAddress, userAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.userLock(userAddress).call()
  return value
}

export async function userStacked ({ blockchain, contractAddress, userAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.userStacked(userAddress).call()
  return value
}

export async function expirationDeposit ({ blockchain, contractAddress, userAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.expirationDeposit(userAddress).call()
  return value
}

export async function manyHarvests ({ blockchain, contractAddress, userAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.manyHarvests(userAddress).call()
  return value
}

export async function getReward ({ blockchain, contractAddress, userAddress, lastRewardIndex = 0 }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const contractLastReward = parseInt(await contract.methods.lastReward().call())
  const userLastReward = parseInt(await contract.methods.userLastReward(userAddress).call())

  if (contractLastReward - userLastReward > 100) {
    let startRewardIndex = userLastReward
    let totalAmount = new BigNumber(0)
    try {
      while (startRewardIndex <= contractLastReward) {
        const { amount } = await contract.methods.getReward(userAddress, startRewardIndex).call()
        startRewardIndex += 100
        totalAmount = totalAmount.plus(amount)
      }
    } catch (err) {
      console.error('getReward err', err)
    }
    return totalAmount.toFixed(0)
  } else {
    const { amount } = await contract.methods.getReward(userAddress, lastRewardIndex).call()
    return amount
  }
}

export async function getWithdrawFeePercent ({ blockchain, contractAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.withdrawFeePercent().call()
  return value
}
