<div class="bg-wrap">
  <div class="container benefits-container">
    <div class="header">Lock your TIME tokens in the TimeWarp smart contract and receive:</div>
    <div class="benefits">
      <div class="benefit">
        Weekly reward payouts. TIME will be purchased from the open market using revenues generated
        by <a href="https://chrono.tech" target="_blank">Chrono.tech</a> ecosystem projects and distributed to stakers;
      </div>
      <div class="benefit">
        Attractive benefits in Chrono.tech services,
        including Premium status and Job Mining for <a href="https://laborx.com" target="_blank">LaborX</a> users.
      </div>
    </div>
  </div>
</div>
