
export const BLOCKCHAIN_ETHEREUM = +process.env.VUE_APP_ETHEREUM_CHAIN_ID
export const BLOCKCHAIN_BINANCE = +process.env.VUE_APP_BINANCE_CHAIN_ID
export const BLOCKCHAIN_POLYGON = +process.env.VUE_APP_POLYGON_CHAIN_ID

export const ALLOWED_NETWORKS = [BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON]

export const NAME_BY_BLOCKCHAIN = {
  [BLOCKCHAIN_ETHEREUM]: 'Ethereum',
  [BLOCKCHAIN_BINANCE]: 'Binance',
  [BLOCKCHAIN_POLYGON]: 'Polygon',
}
export const PROVIDER_BY_CHAIN_ID = {
  [BLOCKCHAIN_ETHEREUM]: process.env.VUE_APP_WEB3_PROVIDER_ETHEREUM,
  [BLOCKCHAIN_BINANCE]: process.env.VUE_APP_WEB3_PROVIDER_BINANCE,
  [BLOCKCHAIN_POLYGON]: process.env.VUE_APP_WEB3_PROVIDER_POLYGON,
}

export const EXPLORER_BY_BLOCKCHAIN = {
  [BLOCKCHAIN_ETHEREUM]: process.env.VUE_APP_EXPLORER_ETHEREUM,
  [BLOCKCHAIN_BINANCE]: process.env.VUE_APP_EXPLORER_BINANCE,
  [BLOCKCHAIN_POLYGON]: process.env.VUE_APP_EXPLORER_POLYGON,
}

export const WEB3_PROVIDER_METAMASK = 'metamask'
export const WEB3_PROVIDER_WALLETCONNECT = 'walletconnect'
export const WALLETCONNECT_PROJECT_ID = process.env.VUE_APP_WALLETCONNECT_PROJECT_ID
export const WALLETCONNECT_RELAY_URL = process.env.VUE_APP_WALLETCONNECT_RELAY_URL
export const WALLETCONNECT_SITE_NAME = 'TimeWarp'
export const WALLETCONNECT_SITE_DESCRIPTION = 'TimeWarp walletconnect'
