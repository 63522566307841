import ModalStack from '@/partials/ModalStack/ModalStack.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ModalStack,
  },
  created () {
    this.loadRate()
    this.initNetwork()
  },
  methods: {
    ...mapActions({
      loadRate: 'loadRate',
      initNetwork: 'network/init'
    })
  }
}
