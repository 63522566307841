import { mapActions, mapGetters, mapState } from 'vuex'
import StakeV2 from '@/modals/StakeV2/StakeV2.vue'
import WithdrawV2 from '@/modals/WithdrawV2/WithdrawV2.vue'
import Harvest from '@/modals/Harvest/Harvest.vue'
import dayjs from 'dayjs'
// import StakingCalculator from '@/modals/StakingCalculator/StakingCalculator.vue'
import { format } from '@/utils/moneyFormat'
import { getCurrency } from '@/utils/currency'
import { NULL_LOCK, LOCK_PERIOD_NAMES, LOCK_MULTIPLIERS } from '@/constants/contract'
import BigNumber from 'bignumber.js'
import { EXPLORER_BY_BLOCKCHAIN } from '@/constants/blockchain'
import { sendGaEvent } from '@/utils/analytics'

export default {
  name: 'PoolV2',
  props: {
    pool: Object,
  },
  computed: {
    ...mapState({
      currencyPrices: state => state.currencyPrices,
      timePrice: state => state.timePrice,
      walletChainIds: (state) => state.network.walletChainIds,
      balances: (state) => state.network.balances,
      userLocksByPool: (state) => state.network.userLocksByPool,
      stackedBalancesByPool: (state) => state.network.stackedBalancesByPool,
      rewardBalanceByPool: (state) => state.network.rewardBalanceByPool,
      expirationTimeByPool: (state) => state.network.expirationTimeByPool,
      totalStackedInPools: (state) => state.network.totalStackedInPool,
      totalStackedInPoolsWithMultipliers: (state) => state.network.totalStackedInPoolWithMultipliers,
    }),
    ...mapGetters({
      rewardApy: 'rewardApy',
      priceApy: 'priceApy',
    }),
    apyText () {
      if (this.poolTotalApy === null) {
        return ''
      }
      return `Pool Multiplier: ${this.isLpPool ? 'x1.2' : 'x1'}<br><br>` +
        `Reward APY: ${this.poolRewardApy}%<br>` +
        `Token Price: ${this.priceApy > 0 ? '+' : ''}${this.priceApy}% <sup>*</sup><br><br>` +
        `<b>Total APY (USD): ${this.poolTotalApy}%</b><br><br>` +
        '<sup>*</sup> The price of the TIME is growing due to the buyback of tokens from the income of the Chrono.Tech ecosystem'
    },
    poolRewardApy () {
      return new BigNumber(this.rewardApy).multipliedBy(this.isLpPool ? 1.2 : 1).toFixed(2)
    },
    poolTotalApy () {
      if (this.timePrice === null) {
        return null
      }
      return new BigNumber(this.poolRewardApy).dividedBy(100)
        .plus(1).multipliedBy(new BigNumber(this.timePrice).toFixed(2)).dividedBy(12).minus(1).multipliedBy(100).toFixed(0)
    },
    currencyDeposit () {
      return getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
    },
    currencyReward () {
      return getCurrency({
        address: this.pool.erc20AddressReward,
        blockchain: this.pool.blockchain,
      })
    },
    contractLink () {
      return `${EXPLORER_BY_BLOCKCHAIN[this.pool.blockchain]}/address/${this.pool.addressPool}`
    },
    balance () {
      return this.balances?.[this.pool.blockchain]?.[this.pool.erc20AddressDeposit] || 0
    },
    stackedBalance () {
      return this.stackedBalancesByPool?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
    },
    totalStackedInPool () {
      return this.totalStackedInPools?.[this.pool.blockchain]?.[this.pool.addressPool] || null
    },
    totalStackedInPoolAsset0 () {
      return this.totalStackedInPools?.[this.pool.blockchain]?.[this.pool.addressPool]?.[this.pool.model.lpAsset0Address] || '0'
    },
    totalStackedInPoolAsset1 () {
      return this.totalStackedInPools?.[this.pool.blockchain]?.[this.pool.addressPool]?.[this.pool.model.lpAsset1Address] || '0'
    },
    totalStackedInPoolWithMultipliers () {
      return this.totalStackedInPoolsWithMultipliers?.[this.pool.blockchain]?.[this.pool.addressPool] || null
    },
    rewardBalance () {
      return this.rewardBalanceByPool?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
    },
    userLock () {
      return this.userLocksByPool?.[this.pool.blockchain]?.[this.pool.addressPool] || NULL_LOCK
    },
    expirationTime () {
      return this.expirationTimeByPool?.[this.pool.blockchain]?.[this.pool.addressPool]
    },
    expirationTimeFormatted () {
      if (!this.expirationTime || this.expirationTime === '0') {
        return 'Date not set'
      }
      const date = new Date(Number(this.expirationTime) * 1000)
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
      }
      return date.toLocaleString('en-US', options)
    },
    balanceFormatted () {
      const currency = getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
      return format(this.balance, {
        divider: currency.baseUnits,
        toFixedNumber: currency.digitsAfterDecimalShow,
      })
    },
    tvlFormatted () {
      const currency = getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
      return format(this.totalStackedInPool, {
        divider: currency.baseUnits,
        toFixedNumber: this.isLpPool ? 10 : 0,
      })
    },
    currencyAsset0 () {
      return getCurrency({
        address: this.pool.model.lpAsset0Address,
        blockchain: this.pool.blockchain,
      })
    },
    currencyAsset1 () {
      return getCurrency({
        address: this.pool.model.lpAsset1Address,
        blockchain: this.pool.blockchain,
      })
    },
    tvlFormattedAsset0 () {
      return format(this.totalStackedInPoolAsset0, {
        divider: this.currencyAsset0.baseUnits,
        toFixedNumber: this.currencyAsset0.digitsAfterDecimalShowInTvl,
      })
    },
    tvlFormattedAsset1 () {
      return format(this.totalStackedInPoolAsset1, {
        divider: this.currencyAsset1.baseUnits,
        toFixedNumber: this.currencyAsset1.digitsAfterDecimalShowInTvl,
      })
    },
    tvlUsd () {
      if (this.totalStackedInPool === null || this.timePrice === null) {
        return null
      }
      const currency = getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
      return format(new BigNumber(this.totalStackedInPool).multipliedBy(this.timePrice), {
        divider: currency.baseUnits,
        toFixedNumber: 0,
      })
    },
    tvlAsset0Usd () {
      if (this.totalStackedInPool === null) {
        return null
      }
      return format(new BigNumber(this.totalStackedInPool[this.pool.model.lpAsset0Address] || 0)
        .multipliedBy(this.currencyPrices[this.currencyAsset0.name] || 0), {
        divider: this.currencyAsset0.baseUnits,
        toFixedNumber: 0,
      })
    },
    tvlAsset1Usd () {
      if (this.totalStackedInPool === null) {
        return null
      }
      return format(new BigNumber(this.totalStackedInPool[this.pool.model.lpAsset1Address] || 0)
        .multipliedBy(this.currencyPrices[this.currencyAsset1.name] || 0), {
        divider: this.currencyAsset1.baseUnits,
        toFixedNumber: 0,
      })
    },
    totalShareFormatted () {
      if (this.totalStackedInPool !== null && this.stackedBalance !== 0) {
        if (!this.isLpPool) {
          return (new BigNumber(this.stackedBalance))
            .dividedBy(this.totalStackedInPool)
            .multipliedBy(100)
            .toFixed(5) + '%'
        } else {
          return (new BigNumber(this.stackedBalance))
            .dividedBy(this.totalStackedInPool.lp)
            .multipliedBy(100)
            .toFixed(5) + '%'
        }
      }
      return null
    },
    totalShareWithMultiplierFormatted () {
      if (this.totalStackedInPoolWithMultipliers !== null && this.stackedBalance !== 0) {
        return (new BigNumber(this.stackedBalance))
          .multipliedBy(LOCK_MULTIPLIERS[this.userLock].value)
          .dividedBy(this.totalStackedInPoolWithMultipliers)
          .multipliedBy(100)
          .toFixed(5) + '%'
      }
      return null
    },
    stackedBalanceFormatted () {
      const currency = getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
      return format(this.stackedBalance, {
        divider: currency.baseUnits,
        toFixedNumber: currency.digitsAfterDecimalShow,
      })
    },
    rewardBalanceFormatted () {
      return format(this.rewardBalance, {
        divider: this.currencyReward.baseUnits,
        toFixedNumber: this.currencyReward.digitsAfterDecimalShow,
      })
    },
    stakeDisabled () {
      return new BigNumber(this.balance)
        .isEqualTo(0)
    },
    withdrawEnabled () {
      if (!this.expirationTime || this.expirationTime === '0') {
        return false
      }
      if (dayjs().isBefore(dayjs.unix(this.expirationTime))) {
        return false
      }
      return new BigNumber(this.stackedBalance).isGreaterThan(0) && this.expirationTime &&
        (this.pool.unlockAll || Number(this.expirationTime) * 1000 <= Date.now())
    },
    harvestDisabled () {
      return new BigNumber(this.rewardBalance)
        .isEqualTo(0)
    },
    isLpPool () {
      return false
      // return this.pool.erc20AddressDeposit.toLowerCase() !== this.pool.erc20AddressReward.toLowerCase()
    },
    compoundDisabled () {
      return new BigNumber(this.rewardBalance)
        .isEqualTo(0)
    },
  },
  watch: {
    userLock: {
      handler () {
        if (this.userLock !== NULL_LOCK) {
          this.open = true
        }
      },
    },
  },
  data () {
    return {
      open: true,
      NULL_LOCK,
      LOCK_PERIOD_NAMES,
      LOCK_MULTIPLIERS
    }
  },
  methods: {
    format,
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    // onCalculatorClick () {
    //   sendGaEvent('calculator_open')
    //   this.openModal({
    //     component: () => StakingCalculator,
    //   })
    // },
    toggleStacking () {
      sendGaEvent('pool_open')
      this.open = !this.open
    },
    stake () {
      this.openModal({
        component: () => StakeV2,
        props: {
          pool: this.pool,
        },
      })
    },
    withdraw () {
      this.openModal({
        component: () => WithdrawV2,
        props: {
          pool: this.pool,
        },
      })
    },
    harvest () {
      this.openModal({
        component: () => Harvest,
        props: {
          pool: this.pool,
          v2: true
        },
      })
    },
    onGetLpClick () {
      sendGaEvent('lp_open')
    }
  },
}
