<div class="page-private page-pools">
  <header-block />
  <div class="page-content page-content-header" :class="{ 'connected': isActiveWallet }">
    <div class="container">
      <h1>MDAO Pool</h1>
      <div class="description-pool">
        Stake your TIME token and get a MDAO token as a reward. <br />
        After adding TIME tokens to the pool, they are blocked until the end date of the promotion. <br />
        You can collect the MDAO reward with tokens at any time after reward <br />
        <a class="dao-token-link" target="_blank" href="https://coinmarketcap.com/currencies/marsdao/">
          <span class="text-link">MDAO token</span>
          <tw-icon class="blank" icon="blank" />
        </a>
      </div>
      <div class="top-info">
        <div class="pool-filter" @click="onPoolSwitchClick">
          <tw-switch class="switch" :modelValue="showOnlyStackedPools" @update:modelValue="onPoolSwitchClick" />
          <span>View pools with already staked balances only</span>
        </div>
        <div v-if="!isActiveWallet" class="connect-wallet" @click="onConnectClick">
          <div class="icon">
            <img src="/static/images/pools/wallet.svg" alt="Connect Wallet" />
          </div>
          <div class="text">
            <div class="big-text">Connect your wallet</div>
            <div class="small-text">to view balances and start staking</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content" :class="{ 'connected': isActiveWallet }">
    <div class="container">
      <div v-if="filteredBscPools.length > 0" class="blockchains">

        <div class="blockchain">
          <div v-if="filteredBscPools.length > 0" class="description">
            <img src="/static/images/pools/bsc.svg" alt="BNB Chain Network" />
            <span>List of pools on the BNB Chain Network</span>
          </div>
          <pool-v2 v-for="pool in filteredBscPools" :pool="pool" :key="pool.name" />
        </div>

      </div>
      <div v-else class="empty">
        <img src="/static/images/pools/empty.svg" alt="Empty" />
        <div>You have no pools with already staked amount</div>
      </div>
    </div>
  </div>
  <footer-block class="footer-block"/>
</div>
