<div class="container stacking-results-container">
  <tw-button class="btn" to="/pools">Start Staking</tw-button>
  <section class="results">
    <div class="item">
      <div class="label">Total Staked</div>
      <tw-loader v-if="+totalStakedInTime === 0 || +totalStakedInEth === 0" class="loader" />
      <template v-else>
        <div class="value">
          {{ totalStakedInTimeFormatted }} TIME<br>
          {{ totalStakedInEthFormatted }} ETH<br>
          {{ totalStakedInBnbFormatted }} BNB<br>
        </div>
        <div class="subvalue">${{ stakedUsd }}</div>
      </template>
    </div>
    <div class="item">
      <div class="label">Tokens Distributed</div>
      <div class="value" v-if="rewards">{{ totalRewardsInTime }} TIME</div>
      <div class="subvalue" v-if="rewards">${{ totalRewardsInUsd }}</div>
    </div>
    <div class="item">
      <div class="label">APY</div>
      <tw-loader v-if="poolRewardApy === null" class="loader" />
      <div v-else class="value">{{ poolRewardApy }}%</div>
    </div>
    <div class="item">
      <div class="label">Stakers</div>
      <div class="value">{{ stakers }}</div>
    </div>
  </section>
  <section class="rewards">
    <div class="title">Weekly Rewards</div>
    <table class="table">
      <thead class="thead row">
        <th class="td">DATE</th>
        <th class="td">REWARD</th>
        <th class="td"></th>
        <th class="td">TXs</th>
      </thead>
      <tr v-for="(reward, i) in rewards" :key="i" class="row" v-if="rewards">
        <td class="td">{{ reward.date }}</td>
        <td class="td">
            {{ rewardFormatted(reward.time) }} TIME
            <template v-if="reward.mdao">
                <br>
                {{ rewardFormatted(reward.mdao) }} MDAO
            </template>
        </td>
        <td class="td">${{ rewardFormatted(reward.usd) }}</td>
        <td class="td txs nowrap">
          <div class="wrap">
            <a class="tx-link" :href="`https://etherscan.io/tx/${reward.tx1}`" target="_blank">
              <tw-icon class="icon eth-icon" icon="eth" />
              <div class="tx">{{ reward.tx1 }}</div>
            </a>
            <br>
            <a v-if="reward.tx2" class="tx-link" :href="`https://etherscan.io/tx/${reward.tx2}`" target="_blank">
              <tw-icon class="icon eth-icon" icon="eth" />
              <div class="tx">{{ reward.tx2 }}</div>
            </a>
          </div>
          <div v-if="reward.tx3" class="wrap">
            <a class="tx-link" :href="`https://bscscan.com/tx/${reward.tx3}`" target="_blank">
              <tw-icon class="icon bsc-icon" icon="bsc" />
              <div class="tx">{{ reward.tx3 }}</div>
            </a>
            <br>
            <a class="tx-link" :href="`https://bscscan.com/tx/${reward.tx4}`" target="_blank">
              <tw-icon class="icon bsc-icon" icon="bsc" />
              <div class="tx">{{ reward.tx4 }}</div>
            </a>
          </div>
          <div v-if="reward.tx5" class="wrap">
            <a class="tx-link" :href="`https://polygonscan.com/tx/${reward.tx5}`" target="_blank">
              <tw-icon class="icon polygon-icon" icon="polygon" />
              <div class="tx">{{ reward.tx5 }}</div>
            </a>
            <br>
            <a class="tx-link" :href="`https://polygonscan.com/tx/${reward.tx6}`" target="_blank">
              <tw-icon class="icon polygon-icon" icon="polygon" />
              <div class="tx">{{ reward.tx6 }}</div>
            </a>
          </div>
        </td>
      </tr>
      <tr v-else v-for="(reward, j) in skeletonRewards" :key="j" class="row">
        <td class="td">
          <skeleton />
        </td>
        <td class="td"><skeleton /></td>
        <td class="td"><skeleton /></td>
        <td class="td txs nowrap">
          <div class="wrap">
            <skeleton />
          </div>
        </td>
      </tr>
    </table>
  </section>
</div>
