import { serializeError } from 'serialize-error'
import extract from 'extract-json-from-string'

/**
 * Get function signature from Transaction.input
 * @param {String} input field from Transaction
 */
export function getFuncSignatureFromInput (input) {
  return input.substring(0, 10)
}

/**
 * Check Transaction that it is specified function invoke
 * @param {Transaction} transaction
 * @param {String} signature AbiItem Signature for function
 */
export function isTransactionInvokeFunc ({ transaction, signature }) {
  if (!transaction) return null
  return transaction.input.substring(0, 10) === signature
}

/**
 * Check Transaction that it is specified function invoke
 * @param {Transaction} transaction
 * @param {String} signature AbiItem Signature for event
 * @param {String} contractAddress Address filter for logs
 */
export function hasTransactionEvent ({ receipt, signature, eventAddress = null }) {
  if (!receipt) return null
  const filteredLogs = (receipt?.logs || [])
    .filter((item) => eventAddress === null || item?.address?.toLowerCase() === eventAddress?.toLowerCase())
  for (const log of filteredLogs) {
    for (const topic of (log?.topics || [])) {
      if (topic === signature) return true
    }
  }
  return false
}

export function extractRevert (error) {
  let lastMessage = null
  try {
    const serialized = serializeError(error)
    lastMessage = serialized?.message
    if (!serialized?.message) return lastMessage
    const extracted = extract(serialized?.message)
    lastMessage = extracted.length <= 0
      ? lastMessage
      : JSON.stringify(extracted[0])
    if (extracted.length <= 0) return lastMessage
    return extracted[0]?.message || extracted[0]?.originalError?.message || serialized?.message
  } catch (err) {
    console.error(err)
    return lastMessage
  }
}
