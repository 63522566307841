<tw-modal class="root account-details">
  <h3 class="modal-title">Account details</h3>
  <section class="section account-section">
    <div class="block-wallet-connection">
      <div class="connect-wallet-text">
        <template v-if="metamaskSelected">
          <tw-icon class="icon metamask-icon" icon="metamask" />
          <span>Metamask Connected</span>
        </template>

        <template v-if="walletConnectSelected">
          <tw-icon class="icon wallet-connect-icon" icon="wallet-connect" />
          <span>WalletConnect Connected</span>
        </template>
      </div>
      <div class="disconnect-btn" @click="disconnect">
        Disconnect
      </div>
    </div>

    <div class="block-wallet-info">
      <tw-icon v-if="ethereumSelected" class="icon eth-icon" icon="eth" />
      <tw-icon v-if="binanceSelected" class="icon bsc-icon" icon="bsc" />
      <tw-icon v-if="polygonSelected" class="icon polygon-icon" icon="polygon" />
      <tw-icon v-else class="icon bsc-icon" icon="account" />
      <span class="address-text">{{ clippedAddress }}</span>
    </div>

    <div class="block-actions">
      <div class="item" @click="copy">
        <tw-icon icon="copy"></tw-icon>
        <span>COPY ADDRESS</span>
      </div>

      <a v-if="ethereumSelected" class="item btn btn-scan-link" :href="linkToEtherscan" target="_blank" rel="noopener">
        <tw-icon icon="link"></tw-icon>
        <span>VIEW ON ETHERSCAN</span>
      </a>
      <a v-if="binanceSelected" class="item btn btn-scan-link" :href="linkToBscscan" target="_blank" rel="noopener">
        <tw-icon icon="link"></tw-icon>
        <span>VIEW ON BSCAN</span>
      </a>
      <a v-if="binanceSelected" class="item btn btn-scan-link" :href="linkToPolygon" target="_blank" rel="noopener">
        <tw-icon icon="link"></tw-icon>
        <span>VIEW ON POLYGON</span>
      </a>
    </div>
  </section>
</tw-modal>
