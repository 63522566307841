import { mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'WalletconnectQr',
  components: {
    QrcodeVue
  },
  props: {
    uri: String,
    error: String
  },
  data () {
    return {
      COPY_MESSAGE: 'Copy to clipboard',
      COPIED_MESSAGE: 'Copied!',
      copyMessage: null
    }
  },
  created () {
    this.copyMessage = this.COPY_MESSAGE
  },
  methods: {
    ...mapActions({
      connectToWallet: 'network/connect',
      closeModal: 'ui/closeModal',
    }),
    async copyLink () {
      const textArea = document.createElement('textarea')
      textArea.value = this.uri
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      this.copyMessage = 'Copied!'
      setTimeout(() => { this.copyMessage = 'Copy to clipboard' }, 3000)
    }
  },
}
