<div class="page-private page-pools">
  <header-block />
  <div class="page-content" :class="{ 'connected': isActiveWallet }">
    <div class="container">
      <h1>Pools</h1>
      <div class="top-info">
        <div class="pool-filter" @click="onPoolSwitchClick">
          <tw-switch class="switch" :modelValue="showOnlyStackedPools" @update:modelValue="onPoolSwitchClick" />
          <span>View pools with already staked balances only</span>
        </div>
        <div v-if="!isActiveWallet" class="connect-wallet" @click="onConnectClick">
          <div class="icon">
            <img src="/static/images/pools/wallet.svg" alt="Connect Wallet" />
          </div>
          <div class="text">
            <div class="big-text">Connect your wallet</div>
            <div class="small-text">to view balances and start staking</div>
          </div>
        </div>
      </div>
      <div v-if="filteredEthPools.length > 0 || filteredBscPools.length > 0 || filteredPolygonPools.length > 0" class="blockchains">
        <div v-if="filteredEthPools.length > 0" class="blockchain">
          <div class="description">
            <img src="/static/images/pools/ethereum.svg" alt="Ethereum Network" />
            <span>List of pools on the Ethereum Network</span>
          </div>
          <pool v-for="pool in filteredEthPools" :pool="pool" :key="pool.name" />
        </div>
        <div class="blockchain">
          <div v-if="filteredBscPools.length > 0" class="description">
            <img src="/static/images/pools/bsc.svg" alt="BNB Chain Network" />
            <span>List of pools on the BNB Chain Network</span>
          </div>
          <pool v-for="pool in filteredBscPools" :pool="pool" :key="pool.name" />
        </div>

        <div class="blockchain">
          <div v-if="filteredPolygonPools.length > 0" class="description">
            <img src="/static/images/logo/polygon.svg" alt="Polygon Network" />
            <span>List of pools on the Polygon Network</span>
          </div>
          <pool v-for="pool in filteredPolygonPools" :pool="pool" :key="pool.name" />
        </div>

      </div>
      <div v-else class="empty">
        <img src="/static/images/pools/empty.svg" alt="Empty" />
        <div>You have no pools with already staked amount</div>
      </div>
    </div>
  </div>
  <footer-block />
</div>
