import Button from './Button/Button.vue'
import Chart from './Chart/Chart.vue'
import Icon from './Icon/Icon.vue'
import Loader from './Loader/Loader.vue'
import Modal from './Modal/Modal.vue'
import NumericInput from './NumericInput/NumericInput.vue'
import NumericInput2 from './NumericInput2/NumericInput.vue'
import ProcessState from './ProcessState/ProcessState.vue'
import Skeleton from './Skeleton/Skeleton.vue'
import Slider from './Slider/Slider.vue'
import Switch from './Switch/Switch.vue'

const components = [Button, Chart, Icon, Loader, Modal, NumericInput, NumericInput2, ProcessState, Skeleton, Slider, Switch]

export default {
  install: (Vue) => {
    for (const component of components) {
      if (component.name) {
        Vue.component(component.name, component)
      }
    }
  },
}
