import { mapActions, mapState } from 'vuex'

export default {
  name: 'Projects',
  data () {
    return {
      dataCollection: null,
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      userLabels: state => state.userLabels,
      userData: state => state.userData,
      gigsLabels: state => state.gigsLabels,
      gigsData: state => state.gigsData,
    }),
    userChartData () {
      if (!this.loaded) {
        return {}
      }
      return (ctx) => {
        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
        gradientStroke.addColorStop(0, '#F987B0')
        gradientStroke.addColorStop(1, '#7037dd')
        return {
          labels: this.userLabels,
          datasets: [
            {
              label: 'Numbers of users',
              borderColor: gradientStroke,
              pointBorderColor: gradientStroke,
              pointBackgroundColor: gradientStroke,
              pointHoverBackgroundColor: gradientStroke,
              pointHoverBorderColor: gradientStroke,
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 12,
              data: this.userData
            }
          ]
        }
      }
    },
    gigsChartData () {
      if (!this.loaded) {
        return {}
      }
      return (ctx) => {
        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
        gradientStroke.addColorStop(0, '#F987B0')
        gradientStroke.addColorStop(1, '#7037dd')
        return {
          labels: this.gigsLabels,
          datasets: [
            {
              label: 'Numbers of Gigs',
              borderColor: gradientStroke,
              pointBorderColor: gradientStroke,
              pointBackgroundColor: gradientStroke,
              pointHoverBackgroundColor: gradientStroke,
              pointHoverBorderColor: gradientStroke,
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 12,
              data: this.gigsData
            }
          ]
        }
      }
    },
    chartOptions () {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            backgroundColor: '#6A50FD'
          }
        },
        scales: {
          x: {
            display: false
          },
        }
      }
    }
  },
  async created () {
    await this.loadStat()
    this.loaded = true // ToDo: add Skeleton
  },
  methods: {
    ...mapActions({
      loadStat: 'loadStat'
    }),
  }
}
