import { mapActions, mapState } from 'vuex'
import { getCurrency } from '@/utils/currency'
import WrongNetwork from '@/partials/WrongNetwork/WrongNetwork.vue'
// import { extractRevert } from '@/utils/transaction'
import { format } from '@/utils/moneyFormat'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/blockchain/transaction'
import { harvest } from '@/servicies/blockchain/contracts/TimeWarp'
import { harvest as harvestV2 } from '@/servicies/blockchain/contracts/TimeWarpV2'
import { extractRevert } from '@/utils/transaction'
import { getExplorerLink } from '@/utils/blockchain'

const STEP_HARVEST = 1
const STEP_HARVEST_PENDING = 5
const STEP_HARVEST_FAIL = 6
const STEP_HARVEST_SUCCESS = 7

export default {
  components: {
    WrongNetwork
  },
  props: {
    pool: Object,
    v2: Boolean
  },
  computed: {
    ...mapState({
      _balances: (state) => state.network.rewardBalanceByPool,
      walletAddress: (state) => state.network.walletAddress,
      walletChainIds: (state) => state.network.walletChainIds,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    isWrongSelectedNetwork () {
      return !this?.walletChainIds?.includes(this.pool.blockchain)
    },
    currency () {
      return getCurrency({
        address: this.pool.erc20AddressReward,
        blockchain: this.pool.blockchain,
      })
    },
    rewardBalanceFormatted () {
      const balanceBase = this._balances?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
      return format(balanceBase, {
        divider: this.currency.baseUnits,
        toFixedNumber: this.currency.digitsAfterDecimalShow,
      })
    },
    explorerLink () {
      return this.tx
        ? getExplorerLink({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        : null
    },
  },
  data () {
    return {
      harvesting: false,
      step: STEP_HARVEST,
      amount: 0,
      tx: null,
      errorMsg: null,
      STEP_HARVEST,
      STEP_HARVEST_PENDING,
      STEP_HARVEST_FAIL,
      STEP_HARVEST_SUCCESS,
    }
  },
  methods: {
    ...mapActions({
      loadBalances: 'network/loadBalances'
    }),
    async harvest () {
      if (this.harvesting) return
      try {
        this.harvesting = true
        this.tx = null
        if (this.v2) {
          this.tx = await harvestV2({
            wallet: this.userSelectedWallet,
            blockchain: this.pool.blockchain,
            contractAddress: this.pool.addressPool,
            from: this.walletAddress,
          })
        } else {
          this.tx = await harvest({
            wallet: this.userSelectedWallet,
            blockchain: this.pool.blockchain,
            contractAddress: this.pool.addressPool,
            from: this.walletAddress,
          })
        }
        this.step = STEP_HARVEST_PENDING
        await awaitTransactionStatusAppliedBlockchain({ blockchain: this.pool.blockchain, tx: this.tx })
        this.loadBalances({ reset: true })
        this.step = STEP_HARVEST_SUCCESS
      } catch (err) {
        this.errorMsg = extractRevert(err)
        console.error(err)
        this.step = STEP_HARVEST_FAIL
      } finally {
        this.harvesting = false
      }
    },
  },
}
