<div class="root tw-numeric-input-2">
  <label v-if="label">{{ label }}</label>
  <div class="input-container">
    <input
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="amount"
      @input.stop.prevent="onInputChange"
      type="number"
      :step="min"
      :max="max"
      :min="min"
      required
    >
  </div>
</div>
