import Loader from '@/components/Loader/Loader.vue'

export default {
  name: 'tw-button',
  components: {
    Loader
  },
  props: {
    loading: Boolean,
    to: String,
  },
  computed: {
    tag () {
      if (this.to) {
        return 'router-link'
      }

      if (this.$attrs.href) {
        return 'a'
      }

      return 'button'
    },
  },
}
