import { getWeb3Async } from '@/servicies/blockchain/web3'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
} from '@/constants/blockchain'

const contractsMap = {
  [BLOCKCHAIN_BINANCE]: {},
  [BLOCKCHAIN_ETHEREUM]: {},
  [BLOCKCHAIN_POLYGON]: {}
}

export async function getContractAsync ({ blockchain, contractAddress }) {
  const web3 = await getWeb3Async({ blockchain })
  const artifacts = await import(/* webpackChunkName: 'timewarp-sc-artifacts' */ 'timewarp-sc-artifacts/artifacts/UniPairV2.json')
  if (!contractsMap[blockchain][contractAddress]) {
    contractsMap[blockchain][contractAddress] = new web3.eth.Contract(artifacts.abi, contractAddress)
  }
  return contractsMap[blockchain][contractAddress]
}

export async function totalSupply ({ blockchain, contractAddress }) {
  const contract = await getContractAsync({ blockchain, contractAddress })
  const value = await contract.methods.totalSupply().call()
  return value
}

export async function getReserves ({ blockchain, contractAddress, who }) {
  if (process.env.NODE_ENV !== 'production') return { _reserve0: 0, _reserve1: 0 }
  const contract = await getContractAsync({ blockchain, contractAddress })
  const { _reserve0, _reserve1 } = await contract.methods.getReserves().call()
  return { _reserve0, _reserve1 }
}
