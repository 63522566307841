import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import { BLOCKCHAIN_BINANCE } from '@/constants/blockchain'
import { POOLS_V2 } from '@/constants/pools'
import HeaderBlock from '@/partials/Header/Header.vue'
import FooterBlock from '@/partials/Footer/Footer.vue'
import ConnectWallet from '@/modals/ConnectWallet/ConnectWallet.vue'
import PoolV2 from './PoolV2/PoolV2.vue'

export default {
  name: 'PoolsV2',
  components: {
    HeaderBlock,
    FooterBlock,
    PoolV2,
  },
  computed: {
    ...mapState({
      stackedBalancesByPool: (state) => state.network.stackedBalancesByPool,
      showOnlyStackedPools: (state) => state.ui.showOnlyStackedPools,
    }),
    ...mapGetters({
      isActiveWallet: 'network/isActiveWallet',
    }),
    filteredBscPools () {
      return this.showOnlyStackedPools
        ? this.bscPools
          .filter((item) => {
            const balanceBase = this.stackedBalancesByPool?.[item.blockchain]?.[item.addressPool] || 0
            return new BigNumber(balanceBase).isGreaterThan(0)
          })
        : this.bscPools
    },
  },
  data () {
    return {
      bscPools: [],
    }
  },
  beforeCreate () {
    document.title = 'MDAO Pool | TimeWarp'
  },
  created () {
    POOLS_V2.filter(item => item.blockchain === BLOCKCHAIN_BINANCE).forEach((pool) => {
      this.bscPools.push({
        model: pool,
        addressPool: pool.address,
        blockchain: pool.blockchain,
        erc20AddressDeposit: pool.erc20AddressDeposit,
        erc20AddressReward: pool.erc20AddressReward,
        unlockAll: pool.unlockAll,
        name: pool.name,
        apy: 'n/a',
        tvl: '$15,913,939.20',
        icon: pool.icon,
        tokenExchangeUrl: `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${pool.erc20AddressDeposit}`,
        active: true,
      })
    })
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    ...mapMutations({
      setShowOnlyStackedPools: 'ui/setShowOnlyStackedPools',
    }),
    onConnectClick () {
      this.openModal({
        component: () => ConnectWallet,
      })
    },
    onPoolSwitchClick () {
      this.setShowOnlyStackedPools(!this.showOnlyStackedPools)
    },
  },
}
