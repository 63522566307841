<tw-modal class="root connect-wallet">
  <h3 class="modal-title">Connect wallet</h3>
  <div class="wrapper-sections">
    <section class="section wallet-section">
      <ul>
        <li @click="connect(WEB3_PROVIDER_METAMASK)">
          <tw-icon class="icon metamask-icon" icon="metamask" />
          <span>Metamask</span>
        </li>
        <li @click="connect(WEB3_PROVIDER_WALLETCONNECT)">
          <tw-icon class="icon wallet-connect-icon" icon="wallet-connect" />
          <span>WalletConnect</span>
        </li>
      </ul>
    </section>
  </div>
</tw-modal>
