import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import {
  Chart,
  ArcElement,
  LineElement,
  PointElement,
  DoughnutController,
  ScatterController,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js'

Chart.register(
  ArcElement,
  DoughnutController,
  LineElement,
  PointElement,
  ScatterController,
  CategoryScale,
  LinearScale,
  Tooltip
)

function getValue (value, ...params) {
  return value instanceof Function ? value(...params) : value
}

function compareData (newData, oldData) {
  return isEqual(
    {
      dataset: newData.datasets.map(dataset => ({
        data: dataset.data,
      })),
      labels: newData.labels,
    },
    {
      dataset: oldData.datasets.map(dataset => ({
        data: dataset.data,
      })),
      labels: oldData.labels,
    },
  )
}
export default {
  name: 'tw-chart',
  props: {
    type: {
      type: String,
      default: 'line',
    },
    data: [Object, Function],
    options: [Object, Function],
  },
  data () {
    return {
      context: null,
    }
  },
  watch: {
    data (newData) {
      const data = getValue(newData, this.context)
      if (!isEqualWith(data, this.chart.data, compareData)) {
        this.chart.data = data
        this.chart.update('none')
      }
    },
  },
  mounted () {
    this.context = this.$el.getContext('2d')
    this.chart = new Chart(this.context, {
      type: this.type,
      data: getValue(this.data, this.context),
      options: getValue(this.options, this.context)
    })
  },
}
