<div class="container time-container">
  <div class="header"><span>Buy Time</span></div>
  <div class="metrics">
    <div class="metric market-cap">
      <div class="name">Market Cap:</div>
      <div class="value">
        <span>
          ${{ marketCap }}
          <skeleton class="loader" v-if="loading" />
        </span>
      </div>
      <div class="coinmarketcap">
        <a href="https://coinmarketcap.com/currencies/chrono-tech/" target="_blank">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="
            M27.4144 19.1209C27.1649 19.2928 26.8736 19.3943 26.5713 19.4146C26.2689 19.4349 25.9667 19.3733 25.6964
            19.2363C25.0619 18.8778 24.72 18.0373 24.72 16.892V13.3817C24.72 11.6967 24.0525 10.4977 22.936
            10.1723C21.0491 9.62019 19.64 11.9315 19.0962 12.8008L15.759 18.2021V11.6101C15.7219 10.0899 15.2275 9.17935
            14.2923 8.90743C13.6743 8.72615 12.7473 8.80031 11.845 10.1764L4.38367 22.1532C3.39092 20.2538 2.87616 18.1411
            2.884 15.9979C2.884 8.77971 8.65199 2.90872 15.759 2.90872C22.866 2.90872 28.6546 8.77971 28.6546
            15.9979V16.035C28.6546 16.035 28.6546 16.0597 28.6546 16.0721C28.7246 17.4688 28.2714 18.5812 27.4186
            19.1209H27.4144ZM31.5344 16.0021V15.932C31.4768 7.13583 24.4233 0 15.759 0C7.09463 0 0 7.17703 0 15.9979C0
            24.8188 7.06991 32 15.759 32C19.7442 31.9998 23.5777 30.4723 26.471 27.7317C26.7518 27.4671 26.9171 27.1025
            26.9309 26.7169C26.9448 26.3313 26.8062 25.9558 26.5451 25.6717C26.4202 25.5335 26.2692 25.4214 26.1007
            25.3418C25.9323 25.2622 25.7498 25.2166 25.5637 25.2078C25.3776 25.199 25.1916 25.2271 25.0164
            25.2904C24.8412 25.3538 24.6802 25.4512 24.5428 25.5769C23.2972 26.7585 21.8256 27.6762 20.2165
            28.2749C18.6074 28.8736 16.8938 29.1409 15.1788 29.0609C13.4638 28.9809 11.7826 28.5551 10.2362
            27.8091C8.68986 27.0632 7.31016 26.0124 6.17999 24.72L12.8956 13.9256V18.9067C12.8956 21.3004 13.8226
            22.0749 14.6013 22.3015C15.3799 22.5281 16.5706 22.3716 17.819 20.3445L21.527 14.3417C21.6423 14.1481
            21.7536 13.9833 21.8525 13.8391V16.892C21.8525 19.1291 22.7506 20.9172 24.3245 21.7989C25.0401 22.1852
            25.8465 22.3719 26.6591 22.3393C27.4717 22.3066 28.2606 22.0559 28.943 21.6135C30.6734 20.4887 31.6168
            18.4535 31.518 16.0021H31.5344Z
          "
            />
          </svg>
          <span>CoinMarketCap</span>
        </a>
      </div>
    </div>
    <div class="metric price">
      <div class="name">Price:</div>
      <div class="value">
        <span>
          ${{ price }}
          <skeleton class="loader" v-if="loading" />
        </span>
      </div>
    </div>
    <div class="metric price">
      <div class="name">Total Supply:</div>
      <div class="value">710,113</div>
    </div>
  </div>
  <div class="exchanges">
    <a href="https://timex.io/buy-time" class="exchange" target="_blank" @click="onExchangeClick">
      <img src="/static/images/logo/timex.svg" alt="TimeX" />
      <span>TimeX</span>
    </a>
    <a href="https://pro.coinbase.com/trade/TIME-USD" class="exchange" target="_blank" @click="onExchangeClick">
      <img class="coinbase" src="/static/images/logo/coinbase.svg" alt="Coinbase" />
      <span>Coinbase</span>
    </a>
    <a href="https://trade.kucoin.com/TIME-BTC" class="exchange" target="_blank" @click="onExchangeClick">
      <img src="/static/images/logo/kucoin.svg" alt="KuCoin" />
      <span>KuCoin</span>
    </a>
    <a href="https://www.gate.io/trade/TIMECHRONO_USDT" class="exchange" target="_blank" @click="onExchangeClick">
      <img class="gate" src="/static/images/logo/gate.svg" alt="Gate.io" />
      <span>Gate.io</span>
    </a>
    <a href="https://www.bybit.com/en-US/trade/spot/TIME/USDT" class="exchange" target="_blank" @click="onExchangeClick">
      <img class="bybit" src="/static/images/logo/bybit.png" alt="Bybit" />
      <span>Bybit</span>
    </a>
    <a href="https://www.bitget.com/en/spot/TIMEUSDT_SPBL?type=spot" class="exchange" target="_blank" @click="onExchangeClick">
      <img class="bitget" src="/static/images/logo/bitget.png" alt="Bitget" />
      <span>Bitget</span>
    </a>
    <a href="https://pancakeswap.finance/swap?outputCurrency=0x3b198e26E473b8faB2085b37978e36c9DE5D7f68" class="exchange" target="_blank" @click="onExchangeClick">
      <img src="/static/images/logo/pancake-1x.png" srcset="/static/images/logo/pancake-2x.png 2x" alt="Pancake" />
      <span>Pancake</span>
    </a>
    <a href="https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xb970bc1bd4fcd639c37aa8efd6713eadc577252f" class="exchange" target="_blank" @click="onExchangeClick">
      <img class="quickswap" src="/static/images/logo/quickswap.png" alt="quickswap" />
      <span>QuickSwap</span>
    </a>
  </div>
</div>
