import { mapActions, mapState, mapGetters } from 'vuex'
import ConnectWallet from '@/modals/ConnectWallet/ConnectWallet.vue'
import AccountDetails from '@/modals/AccountDetails/AccountDetails.vue'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  WEB3_PROVIDER_METAMASK,
  WEB3_PROVIDER_WALLETCONNECT,
  BLOCKCHAIN_POLYGON,
} from '@/constants/blockchain'
import { sendGaEvent } from '@/utils/analytics'

export default {
  name: 'HeaderBlock',
  computed: {
    ...mapState({
      walletChainIds: (state) => state.network.walletChainIds,
      walletAddress: (state) => state.network.walletAddress,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    ...mapGetters({
      isActiveWallet: 'network/isActiveWallet',
      isWrongNetwork: 'network/isWrongNetwork',
    }),
    clippedAddress () {
      if (!this.walletAddress) return ''
      return `${this.walletAddress.slice(0, 6)}...${this.walletAddress.slice(-4)}`
    },
    metamaskSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_METAMASK
    },
    walletConnectSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT
    },
    binanceSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_BINANCE)
    },
    ethereumSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_ETHEREUM)
    },
    polygonSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_POLYGON)
    },
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
  },
  data () {
    return {
      menu: false,
      isConnected: false,
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onConnectClick () {
      this.menu = false
      this.openModal({
        component: () => ConnectWallet,
      })
      sendGaEvent('wallet_connect')
    },
    accountDetails () {
      this.menu = false
      this.openModal({
        component: () => AccountDetails,
      })
    },
  },
}
