<nav class="header">
  <div class="top-panel">
    <router-link class="logo" to="/">
      <img src="/static/images/logo/timewarp-1x.png" srcset="/static/images/logo/timewarp-2x.png 2x" alt="Logo" />
      <span>TimeWarp</span>
    </router-link>
    <div class="side-right">
      <div class="header-nav menu">
        <router-link class="active" to="/">Home</router-link>
        <router-link to="/pools">Pools</router-link>
        <router-link to="/mdao">MDAO Pool</router-link>
        <a href="https://docs.timewarp.finance" target="_blank">Docs</a>
      </div>
      <tw-button class="connect-btn" v-if="!isActiveWallet" @click="onConnectClick">Connect Wallet</tw-button>
      <template v-else>
        <div class="address" @click="accountDetails">
          <template v-if="isWrongNetwork" >
            <tw-icon class="icon bsc-icon" icon="account" />
            <div class="wrong-network">Wrong Network</div>
          </template>

          <template v-else>
            <tw-icon v-if="ethereumSelected" class="icon eth-icon" icon="eth" />
            <tw-icon v-if="binanceSelected" class="icon bsc-icon" icon="bsc" />
            <tw-icon v-if="polygonSelected" class="icon polygon-icon" icon="polygon" />
            <span >{{ clippedAddress }}</span>
          </template>
        </div>
      </template>
    </div>
    <transition-group name="btn">
      <button class="mobile-menu-btn close-btn" key="open" v-if="menu" @click="menu = !menu"></button>
      <button class="mobile-menu-btn open-btn" key="close" v-else @click="menu = !menu"></button>
    </transition-group>
  </div>
  <transition name="fall" type="animation">
    <div class="header-nav mobile-nav" :class="menu ? 'menu-open' : 'menu-close'" v-if="menu">
      <router-link class="link active" to="/">Home</router-link>
      <router-link class="link" to="/pools">Pools</router-link>
      <router-link class="link" to="/mdao">MDAO Pool</router-link>
      <a class="link" href="https://docs.timewarp.finance" target="_blank">Docs</a>
      <div class="bottom-part">
        <tw-button v-if="!isActiveWallet" class="connect-btn" @click="onConnectClick">CONNECT WALLET</tw-button>
        <template v-else>
          <div class="address">
            <tw-icon class="icon bsc-icon" :class="{ wrong: isWrongNetwork }" icon="account" />
            <div v-if="isWrongNetwork" class="wrong-network">Wrong Network</div>
            <span v-else>{{ clippedAddress }}</span>
          </div>
        </template>
      </div>
    </div>
  </transition>
</nav>
