<div class="root tw-numeric-input">
  <label v-if="label">{{ label }}</label>
  <div class="input-container">
    <input
      v-if="!readOnly"
      ref="numeric"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="amount"
      type="tel"
      @blur="onBlurHandler"
      @input.stop.prevent="onInputHandler"
      @focus="onFocusHandler"
    >
    <span v-else ref="readOnly">{{ amount }}</span>
    <span class="postfix" v-if="postfix">{{ postfix }}</span>
  </div>
</div>
