import Pool from '@/models/Pool'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_POLYGON
} from '@/constants/blockchain'

export const POOLS = [
  ...(JSON.parse(process.env.VUE_APP_BINANCE_POOLS)
    .map((item) => new Pool({ ...item, blockchain: BLOCKCHAIN_BINANCE }))),
  ...(JSON.parse(process.env.VUE_APP_ETHEREUM_POOLS)
    .map((item) => new Pool({ ...item, blockchain: BLOCKCHAIN_ETHEREUM }))),
  ...(JSON.parse(process.env.VUE_APP_POLYGON_POOLS)
    .map((item) => new Pool({ ...item, blockchain: BLOCKCHAIN_POLYGON }))),
]

export const POOLS_V2 = [
  ...(JSON.parse(process.env.VUE_APP_BINANCE_POOLS_V2)
    .map((item) => new Pool({ ...item, blockchain: BLOCKCHAIN_BINANCE })))
]
