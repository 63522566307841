<div class="page">
  <header-block />
  <intro />
  <stacking-results />
<!--   <timer /> -->
  <benefits />
  <time-block />
  <projects />
  <subscribe />
<!--  <partners />-->
  <faq />
  <footer-block />
</div>
