import uniqueId from 'lodash/uniqueId'

const getInitialState = () => ({
  modalStack: [],
  showOnlyStackedPools: false,
})

export default () => {
  return ({
    namespaced: true,
    state: getInitialState(),
    getters: {
      getIsOpenedModal (state) {
        return state.modalStack.length > 0
      },
    },
    mutations: {
      addToModalStack: (state, modalData) => {
        state.modalStack.push(modalData)
      },
      removeFromModalStack: (state, id) => {
        if (id) {
          const index = state.modalStack.findIndex(modal => modal.id === id)
          if (index !== -1) {
            state.modalStack.splice(index, 1)
          }
        } else {
          state.modalStack.pop()
        }
      },
      setShowOnlyStackedPools: (state, flag) => {
        state.showOnlyStackedPools = !!flag
      },
      updateModalParams: (state, { id, payload }) => {
        const index = state.modalStack.findIndex(modal => modal.id === id)
        if (index !== -1) {
          Object.keys(payload).map(modalStackPropName => {
            if (modalStackPropName === 'props') {
              Object.keys(payload.props).map(propName => {
                state.modalStack[index].props[propName] = payload.props[propName]
              })
            } else {
              state.modalStack[index][modalStackPropName] = payload[modalStackPropName]
            }
          })
        }
      },
    },
    actions: {
      openModal: ({ state, commit }, modalData) => {
        if (modalData.id && state.modalStack.find(modal => modal.id === modalData.id)) {
          return modalData.id
        }
        const id = modalData.id || uniqueId('modal_')
        commit('addToModalStack', { id, ...modalData })
        return id
      },
      closeModal: ({ commit }, id) => {
        commit('removeFromModalStack', typeof id === 'string' ? id : null)
      },
      updateModalParams: ({ commit }, { id, payload }) => {
        commit('updateModalParams', { id, payload })
      }
    },
  })
}
