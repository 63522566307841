import numeral from 'numeral'

export default {
  name: 'tw-numeric-input',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    postfix: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Maximum value allowed.
     */
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false,
    },
    /**
     * Minimum value allowed.
     */
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false
    },
    /**
     * Enable/Disable minus value.
     */
    minus: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Number of decimals.
     * Decimals symbol are the opposite of separator symbol.
     */
    precision: {
      type: Number,
      default: 8,
      required: false
    },
    /**
     * Thousand separator type.
     * Separator props accept either . or , (default).
     */
    separator: {
      type: String,
      default: ',',
      required: false
    },
    /**
     * v-model value.
     */
    modelValue: {
      type: [Number, String],
      default: 0,
      required: true
    },
    /**
     * Hide input and show value in text only.
     */
    readOnly: Boolean,
    disabled: Boolean,
  },
  data () {
    return {
      amount: ''
    }
  },
  computed: {
    /**
     * Number type of formatted value.
     * @return {Number}
     */
    amountNumber () {
      return this.unformat(this.amount)
    },
    /**
     * Number type of value props.
     * @return {Number}
     */
    valueNumber () {
      return this.unformat(this.modelValue)
    },
    formatTemplate () {
      return `0${this.separator}0.[${'0'.repeat(this.precision)}]`
    },
  },
  watch: {
    /**
     * Watch for value change from other input with same v-model.
     * @param {Number} newValue
     */
    valueNumber (newValue) {
      if (this.$refs.numeric !== document.activeElement) {
        this.amount = this.format(newValue)
      }
    },
    /**
     * Immediately reflect precision changes
     */
    precision () {
      this.process(this.valueNumber)
      this.amount = this.format(this.valueNumber)
    },
  },
  mounted () {
    // Set default value props when valueNumber has some value
    this.process(this.valueNumber)
    this.amount = this.format(this.valueNumber)
  },
  methods: {
    /**
     * Handle blur event.
     * @param {Object} e
     */
    onBlurHandler (e) {
      this.$emit('blur', this.getFixedValue(this.amountNumber))
      this.amount = this.format(this.valueNumber)
    },
    /**
     * Handle focus event.
     * @param {Object} e
     */
    onFocusHandler (e) {
      this.$emit('focus', e)
      if (this.valueNumber === 0) {
        this.amount = null
      } else {
        this.amount = this.valueNumber
      }
    },
    /**
     * Handle input event.
     */
    onInputHandler (e) {
      this.$emit('input', e)
      this.process(this.amountNumber)
    },
    /**
     * Validate value before update the component.
     * @param {Number} value
     */
    process (value) {
      if (value >= this.max) this.update(this.max)
      if (value <= this.min) this.update(this.min)
      if (value > this.min && value < this.max) this.update(value)
      if (!this.minus && value < 0) this.min >= 0 ? this.update(this.min) : this.update(0)
    },
    /**
     * Update parent component model value.
     * @param {Number} value
     */
    getFixedValue (value) {
      return Number(value).toFixed(this.precision)
    },
    update (value) {
      const fixedValue = this.getFixedValue(value)
      this.$emit('update:modelValue', Number(fixedValue))
    },
    /**
     * Format value using symbol and separator.
     * @param {Number} value
     * @return {String}
     */
    format (value) {
      return numeral(value).format(this.formatTemplate)
    },
    /**
     * Remove symbol and separator.
     * @param {Number} value
     * @return {Number}
     */
    unformat (value) {
      const toUnformat = typeof value === 'string' && value === '' ? 0 : value
      return numeral(toUnformat).value()
    }
  }
}
