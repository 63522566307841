import BigNumber from 'bignumber.js'
import { mapActions, mapState } from 'vuex'
import WrongNetwork from '@/partials/WrongNetwork/WrongNetwork.vue'
import { getCurrency } from '@/utils/currency'
import { extractRevert } from '@/utils/transaction'
import { format } from '@/utils/moneyFormat'
import { allowance as allowance_, approve } from '@/servicies/blockchain/contracts/Erc20'
import { awaitTransactionStatusAppliedBlockchain } from '@/servicies/blockchain/transaction'
import { deposit } from '@/servicies/blockchain/contracts/TimeWarp'
import { getExplorerLink } from '@/utils/blockchain'
import { NAME_BY_BLOCKCHAIN } from '@/constants/blockchain'
import {
  HOURS1,
  DAYS30,
  DAYS180,
  DAYS365,
  DAYS730,
  MAX_AMOUNT_APPROVE,
  NULL_LOCK,
  LOCK_MULTIPLIERS,
} from '@/constants/contract'
import { sendGaEvent } from '@/utils/analytics'

const STEP_STAKE = 1
const STEP_APPROVE_PENDING = 2
const STEP_APPROVE_FAIL = 3
const STEP_APPROVE_SUCCESS = 4
const STEP_STAKE_PENDING = 5
const STEP_STAKE_FAIL = 6
const STEP_STAKE_SUCCESS = 7

export default {
  components: {
    WrongNetwork,
  },
  props: {
    pool: Object,
  },
  computed: {
    ...mapState({
      _balances: (state) => state.network.balances,
      userLocksByPool: (state) => state.network.userLocksByPool,
      rewardBalanceByPool: (state) => state.network.rewardBalanceByPool,
      walletAddress: (state) => state.network.walletAddress,
      walletChainIds: (state) => state.network.walletChainIds,
      userSelectedWallet: (state) => state.network.userSelectedWallet,
    }),
    userLock () {
      return this.userLocksByPool?.[this.pool.blockchain]?.[this.pool.addressPool] || NULL_LOCK
    },
    isWrongSelectedNetwork () {
      return !this?.walletChainIds?.includes(this.pool.blockchain)
    },
    requiredBlockchainName () {
      return NAME_BY_BLOCKCHAIN[this.pool.blockchain]
    },
    balances () {
      return this._balances?.[this.pool.blockchain]
    },
    rewardBalance () {
      return this.rewardBalanceByPool?.[this.pool.blockchain]?.[this.pool.addressPool] || 0
    },
    currencyReward () {
      return getCurrency({
        address: this.pool.erc20AddressReward,
        blockchain: this.pool.blockchain,
      })
    },
    rewardBalanceFormatted () {
      return format(this.rewardBalance, {
        divider: this.currencyReward.baseUnits,
        toFixedNumber: this.currencyReward.digitsAfterDecimalShow,
      })
    },
    currency () {
      return getCurrency({
        address: this.pool.erc20AddressDeposit,
        blockchain: this.pool.blockchain,
      })
    },
    stakeBalanceFormatted () {
      const balanceBase = this._balances?.[this.pool.blockchain]?.[this.pool.erc20AddressDeposit] || 0
      return format(balanceBase, {
        divider: this.currency.baseUnits,
        toFixedNumber: this.currency.digitsAfterDecimalShow,
      })
    },
    stakeBalance () {
      const balanceBase = this._balances?.[this.pool.blockchain]?.[this.pool.erc20AddressDeposit] || 0
      return new BigNumber(balanceBase).dividedBy(this.currency.baseUnits).toFixed().toString()
    },
    amountBase () {
      return new BigNumber(this.amount.toString()
        .replace(',', '').replace(/\.$/, '')).multipliedBy(this.currency.baseUnits)
    },
    approveActive () {
      return (this.allowance && this.amountBase.isGreaterThan(this.allowance)) || (this.allowance !== null && this.allowance === '0')
    },
    explorerLink () {
      return this.tx
        ? getExplorerLink({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        : null
    },
    lockingTypePostfix () {
      switch (this.lockType) {
        case HOURS1:
          return 'hour'
        case DAYS30:
        case DAYS180:
          return 'days'
        case DAYS365:
          return 'year'
        case DAYS730:
          return 'years'
      }
    },
    lockingTypeValue () {
      switch (this.lockType) {
        case HOURS1:
        case DAYS365:
          return 1
        case DAYS30:
          return 30
        case DAYS180:
          return 180
        case DAYS730:
          return 2
      }
    },
    hasValidationError () {
      if (this.userLock > this.lockType) {
        return true
      }
      return false
    },
    isLpPool () {
      return this.pool.erc20AddressDeposit.toLowerCase() !== this.pool.erc20AddressReward.toLowerCase()
    },
  },
  data () {
    return {
      init: false,
      staking: false,
      approving: false,
      step: STEP_STAKE,
      amount: '',
      HOURS1,
      DAYS30,
      DAYS180,
      DAYS365,
      DAYS730,
      LOCK_MULTIPLIERS,
      allowance: null,
      tx: null,
      errorMsg: null,
      lockType: DAYS365,
      lockPeriods: [HOURS1, DAYS30, DAYS180, DAYS365, DAYS730],
      STEP_STAKE,
      STEP_APPROVE_PENDING,
      STEP_APPROVE_FAIL,
      STEP_APPROVE_SUCCESS,
      STEP_STAKE_PENDING,
      STEP_STAKE_FAIL,
      STEP_STAKE_SUCCESS,
      compound: false,
    }
  },
  async mounted () {
    this.lockType = +this.userLock ? this.userLock : DAYS365
    this.max()
    this.allowance = await allowance_({
      blockchain: this.pool.blockchain,
      contractAddress: this.currency.address,
      owner: this.walletAddress,
      spender: this.pool.addressPool,
    })
    this.init = true
  },
  methods: {
    ...mapActions({
      loadBalances: 'network/loadBalances',
    }),
    max () {
      this.amount = this.stakeBalance
    },
    stakeAndCompound () {
      this.compound = true
    },
    stakeAndHarvest () {
      this.compound = false
    },
    async stake () {
      if (this.approveActive) {
        await this.approve()
        return
      }
      if (this.staking) return
      try {
        this.staking = true
        this.tx = null
        this.tx = await deposit({
          wallet: this.userSelectedWallet,
          blockchain: this.pool.blockchain,
          contractAddress: this.pool.addressPool,
          from: this.walletAddress,
          lockType: this.lockType,
          amount: this.amountBase.toFixed(0),
          compound: this.compound,
        })
        this.step = STEP_STAKE_PENDING
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        this.loadBalances({ reset: true })
        sendGaEvent('deposit_success')
        this.step = STEP_STAKE_SUCCESS
      } catch (err) {
        this.errorMsg = extractRevert(err)
        console.error(err)
        this.step = STEP_STAKE_FAIL
      } finally {
        this.staking = false
      }
    },
    async approve () {
      if (this.approving) return
      try {
        this.approving = true
        this.tx = null
        this.tx = await approve({
          wallet: this.userSelectedWallet,
          blockchain: this.pool.blockchain,
          contractAddress: this.currency.address,
          from: this.walletAddress,
          spender: this.pool.addressPool,
          amount: MAX_AMOUNT_APPROVE,
        })
        this.step = STEP_APPROVE_PENDING
        await awaitTransactionStatusAppliedBlockchain({
          blockchain: this.pool.blockchain,
          tx: this.tx,
        })
        this.allowance = await allowance_({
          blockchain: this.pool.blockchain,
          contractAddress: this.currency.address,
          owner: this.walletAddress,
          spender: this.pool.addressPool,
        })
        this.loadBalances({ reset: true })
        this.step = STEP_STAKE
      } catch (err) {
        this.errorMsg = extractRevert(err)
        console.error(err)
        this.step = STEP_APPROVE_FAIL
      } finally {
        this.approving = false
      }
    },
  },
}
