import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_BINANCE, BLOCKCHAIN_POLYGON } from '@/constants/blockchain'
import { POOLS } from '@/constants/pools'
import HeaderBlock from '@/partials/Header/Header.vue'
import FooterBlock from '@/partials/Footer/Footer.vue'
import ConnectWallet from '@/modals/ConnectWallet/ConnectWallet.vue'
import Pool from './Pool/Pool.vue'

export default {
  name: 'Home',
  components: {
    HeaderBlock,
    FooterBlock,
    Pool,
  },
  computed: {
    ...mapState({
      stackedBalancesByPool: (state) => state.network.stackedBalancesByPool,
      showOnlyStackedPools: (state) => state.ui.showOnlyStackedPools,
    }),
    ...mapGetters({
      isActiveWallet: 'network/isActiveWallet',
    }),
    filteredEthPools () {
      return this.showOnlyStackedPools
        ? this.ethPools
          .filter((item) => {
            const balanceBase = this.stackedBalancesByPool?.[item.blockchain]?.[item.addressPool] || 0
            return new BigNumber(balanceBase).isGreaterThan(0)
          })
        : this.ethPools
    },
    filteredBscPools () {
      return this.showOnlyStackedPools
        ? this.bscPools
          .filter((item) => {
            const balanceBase = this.stackedBalancesByPool?.[item.blockchain]?.[item.addressPool] || 0
            return new BigNumber(balanceBase).isGreaterThan(0)
          })
        : this.bscPools
    },
    filteredPolygonPools () {
      return this.showOnlyStackedPools
        ? this.polygonPools
          .filter((item) => {
            const balanceBase = this.stackedBalancesByPool?.[item.blockchain]?.[item.addressPool] || 0
            return new BigNumber(balanceBase).isGreaterThan(0)
          })
        : this.polygonPools
    },
  },
  data () {
    return {
      ethPools: [],
      bscPools: [],
      polygonPools: [],
    }
  },
  beforeCreate () {
    document.title = 'Pools | TimeWarp'
  },
  created () {
    POOLS.filter(item => item.blockchain === BLOCKCHAIN_ETHEREUM).forEach((pool) => {
      this.ethPools.push({
        model: pool,
        addressPool: pool.address,
        blockchain: pool.blockchain,
        erc20AddressDeposit: pool.erc20AddressDeposit,
        erc20AddressReward: pool.erc20AddressReward,
        unlockAll: pool.unlockAll,
        name: pool.name,
        apy: '150%',
        tvl: '$15,913,939.20',
        icon: pool.icon,
        tokenExchangeUrl: `https://app.uniswap.org/#/swap?outputCurrency=${pool.erc20AddressDeposit}`,
        active: true,
      })
    })

    POOLS.filter(item => item.blockchain === BLOCKCHAIN_BINANCE).forEach((pool) => {
      this.bscPools.push({
        model: pool,
        addressPool: pool.address,
        blockchain: pool.blockchain,
        erc20AddressDeposit: pool.erc20AddressDeposit,
        erc20AddressReward: pool.erc20AddressReward,
        unlockAll: pool.unlockAll,
        name: pool.name,
        apy: 'n/a',
        tvl: '$15,913,939.20',
        icon: pool.icon,
        tokenExchangeUrl: `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${pool.erc20AddressDeposit}`,
        active: true,
      })
    })

    POOLS.filter(item => item.blockchain === BLOCKCHAIN_POLYGON).forEach((pool) => {
      this.polygonPools.push({
        model: pool,
        addressPool: pool.address,
        blockchain: pool.blockchain,
        erc20AddressDeposit: pool.erc20AddressDeposit,
        erc20AddressReward: pool.erc20AddressReward,
        unlockAll: pool.unlockAll,
        name: pool.name,
        apy: 'n/a',
        tvl: '$15,913,939.20',
        icon: pool.icon,
        tokenExchangeUrl: 'https://quickswap.exchange/#/swap',
        active: true,
      })
    })
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    ...mapMutations({
      setShowOnlyStackedPools: 'ui/setShowOnlyStackedPools',
    }),
    onConnectClick () {
      this.openModal({
        component: () => ConnectWallet,
      })
    },
    onPoolSwitchClick () {
      this.setShowOnlyStackedPools(!this.showOnlyStackedPools)
    },
  },
}
