import Currency from '@/models/Currency'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_POLYGON
} from '@/constants/blockchain'

export const CURRENCIES = [
  ...(JSON.parse(process.env.VUE_APP_BINANCE_CURRENCIES)
    .map((item) => Currency.fromEnv({ ...item, blockchain: BLOCKCHAIN_BINANCE }))),
  ...(JSON.parse(process.env.VUE_APP_ETHEREUM_CURRENCIES)
    .map((item) => Currency.fromEnv({ ...item, blockchain: BLOCKCHAIN_ETHEREUM }))),
  ...(JSON.parse(process.env.VUE_APP_POLYGON_CURRENCIES)
    .map((item) => Currency.fromEnv({ ...item, blockchain: BLOCKCHAIN_POLYGON }))),
]
