<div class="modal-stack">
  <transition-group name="open">
    <div
      class="modal-container"
      :class="modal.addClass"
      v-for="modal in modals"
      :key="modal.id"
    >
      <component
        class="modal"
        :style="modal.style"
        :is="modal.component()"
        v-bind="modal.props"
        @close="closeModal(modal.id)"
      />
    </div>
  </transition-group>
</div>
