import { NAME_BY_BLOCKCHAIN } from '@/constants/blockchain'

export default {
  name: 'wrong-network',
  props: {
    requiredBlockchain: Number
  },
  computed: {
    requiredBlockchainName () {
      return NAME_BY_BLOCKCHAIN[this.requiredBlockchain]
    },
  },
}
