import BigNumber from 'bignumber.js'
import {
  WEB3_PROVIDER_METAMASK,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_POLYGON,
  ALLOWED_NETWORKS,
  WEB3_PROVIDER_WALLETCONNECT
} from '@/constants/blockchain'
import { CURRENCIES } from '@/constants/currencies'
import { POOLS, POOLS_V2 } from '@/constants/pools'
import { NULL_LOCK } from '@/constants/contract'
import {
  userStacked,
  getReward,
  userLock,
  expirationDeposit,
  totalStakedInPool,
  totalStakedInPoolWithMultipliers,
} from '@/servicies/blockchain/contracts/TimeWarp'
import {
  eventEmitter as eventEmitterMetamask,
  isMetamaskInstalled,
  getAddress as getAddressMetamask,
  getChainId as getChainIdMetamask,
  addChain,
} from '@/servicies/blockchain/metamask'
import {
  eventEmitter as eventEmitterWalletConnect,
  getProviderAsync,
  getChainsAndAddress as getChainsAndAddressWalletConnect,
  sessionConnect,
  getSession,
  resetSession
} from '@/servicies/blockchain/walletConnect'
import { totalSupply, getReserves } from '@/servicies/blockchain/contracts/UniPair'
import { getBalance } from '@/servicies/blockchain/balance'
import { getCurrency } from '@/utils/currency'
import WalletconnectQr from '@/modals/WalletconnectQr/WalletconnectQr.vue'
import { expirationDeposit as expirationDepositV2, totalStakedInPool as totalStakedInPoolV2 }
from '@/servicies/blockchain/contracts/TimeWarpV2'

const BALANCES_UPDATE_INTERVAL = +process.env.VUE_APP_BALANCES_UPDATE_INTERVAL || 30000

const zeroBalancesByBlockchain = () => {
  return {
    [BLOCKCHAIN_ETHEREUM]: {},
    [BLOCKCHAIN_BINANCE]: {},
    [BLOCKCHAIN_POLYGON]: {},
  }
}

const getInitialState = () => ({
  userSelectedWallet: null,
  walletConnected: false,
  walletConnectUri: null,
  walletConnectModalId: null,
  walletAddress: null,
  walletChainIds: null,
  intervalBalances: null,
  balances: zeroBalancesByBlockchain(),
  userLocksByPool: zeroBalancesByBlockchain(),
  totalStackedInPool: zeroBalancesByBlockchain(),
  totalStackedInPoolWithMultipliers: zeroBalancesByBlockchain(),
  stackedBalancesByPool: zeroBalancesByBlockchain(),
  rewardBalanceByPool: zeroBalancesByBlockchain(),
  expirationTimeByPool: zeroBalancesByBlockchain(),
})

export default () => {
  return ({
    namespaced: true,
    state: getInitialState(),
    getters: {
      isActiveWallet (state) {
        if (state.userSelectedWallet === WEB3_PROVIDER_METAMASK) {
          return isMetamaskInstalled() && state.walletConnected
        }
        if (state.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT) {
          return state.walletConnected
        }
        return false
      },
      isWrongNetwork (state) {
        return !ALLOWED_NETWORKS.some(chain =>
          state?.walletChainIds?.includes(chain)
        )
      },
      totalStakedInTime (state) {
        let staked = new BigNumber(0)
        for (const blockchain in state.totalStackedInPool) {
          const currency = getCurrency({
            name: 'TIME',
            blockchain: +blockchain,
          })
          for (const address in state.totalStackedInPool[blockchain]) {
            let balance = 0
            if (state.totalStackedInPool[blockchain][address].lp) {
              balance = state.totalStackedInPool[blockchain][address][currency.address]
            } else {
              balance = state.totalStackedInPool[blockchain][address]
            }
            staked = staked.plus(balance)
          }
        }
        const currency = getCurrency({
          name: 'TIME',
          blockchain: BLOCKCHAIN_ETHEREUM,
        })
        return staked.dividedBy(currency.baseUnits).toFixed(0, 1)
      },
      totalStakedInEth (state) {
        let staked = new BigNumber(0)
        const currencyEthereum = getCurrency({
          secondName: 'WETH',
          blockchain: BLOCKCHAIN_ETHEREUM,
        })
        for (const address in state.totalStackedInPool[BLOCKCHAIN_ETHEREUM]) {
          let balance = 0
          if (state.totalStackedInPool[BLOCKCHAIN_ETHEREUM][address].lp) {
            balance = state.totalStackedInPool[BLOCKCHAIN_ETHEREUM][address][currencyEthereum.address]
          }
          staked = staked.plus(balance)
        }
        const currencyPolygon = getCurrency({
          secondName: 'WETH',
          blockchain: BLOCKCHAIN_POLYGON,
        })
        if (currencyPolygon) {
          for (const address in state.totalStackedInPool[BLOCKCHAIN_POLYGON]) {
            let balance = 0
            if (state.totalStackedInPool[BLOCKCHAIN_POLYGON][address].lp) {
              balance = state.totalStackedInPool[BLOCKCHAIN_POLYGON][address][currencyPolygon.address]
            }
            staked = staked.plus(balance)
          }
        }
        return staked.dividedBy(currencyEthereum.baseUnits).toFixed(2, 1)
      },
      totalStakedInBnb (state) {
        let staked = new BigNumber(0)
        const currency = getCurrency({
          secondName: 'WBNB',
          blockchain: BLOCKCHAIN_BINANCE,
        })
        for (const address in state.totalStackedInPool[BLOCKCHAIN_BINANCE]) {
          let balance = 0
          if (state.totalStackedInPool[BLOCKCHAIN_BINANCE][address].lp) {
            if (state.totalStackedInPool[BLOCKCHAIN_BINANCE][address][currency.address]) {
              balance = state.totalStackedInPool[BLOCKCHAIN_BINANCE][address][currency.address]
            }
          }
          staked = staked.plus(balance)
        }
        return staked.dividedBy(currency.baseUnits).toFixed(2, 1)
      },
      totalStakedInMatic (state) {
        let staked = new BigNumber(0)
        const currency = getCurrency({
          secondName: 'WMATIC',
          blockchain: BLOCKCHAIN_POLYGON,
        })
        for (const address in state.totalStackedInPool[BLOCKCHAIN_POLYGON]) {
          let balance = 0
          if (state.totalStackedInPool[BLOCKCHAIN_POLYGON][address].lp) {
            balance = state.totalStackedInPool[BLOCKCHAIN_POLYGON][address][currency.address]
          }
          staked = staked.plus(balance)
        }
        return staked.dividedBy(currency.baseUnits).toFixed(2, 1)
      },
      walletConnectModalId (state) {
        return state.walletConnectModalId
      },
      walletConnectError (state) {
        return state.walletConnectError
      },
    },
    mutations: {
      setUserSelectedWallet: (state, wallet) => {
        state.userSelectedWallet = wallet
      },
      setWalletConnected: (state, flag) => {
        state.walletConnected = flag
      },
      setWalletConnectUri: (state, url) => {
        state.walletConnectUri = url
      },
      setWalletConnectModalId: (state, url) => {
        state.walletConnectModalId = url
      },
      setWalletAddress: (state, address) => {
        state.walletAddress = address
      },
      setWalletChainIds: (state, chainIds) => {
        state.walletChainIds = chainIds
      },
      setIntervalBalances: (state, interval) => {
        state.setIntervalBalances = interval
      },
      setZeroBalances: (state) => {
        state.balances = zeroBalancesByBlockchain()
      },
      setZeroTotalStackedInPool: (state) => {
        state.balances = zeroBalancesByBlockchain()
      },
      setZeroTotalStackedInPoolWithMultipliers: (state) => {
        state.balances = zeroBalancesByBlockchain()
      },
      setZeroStackedBalances: (state) => {
        state.stackedBalancesByPool = zeroBalancesByBlockchain()
      },
      setZeroUserLocks: (state) => {
        state.userLocksByPool = zeroBalancesByBlockchain()
      },
      setTotalStackedInPool: (state, {
        blockchain,
        poolAddress,
        balance,
      }) => {
        state.totalStackedInPool = {
          ...state.totalStackedInPool,
          [blockchain]: {
            ...state.totalStackedInPool[blockchain],
            [poolAddress]: balance,
          },
        }
      },
      setTotalStackedInPoolWithMultipliers: (state, {
        blockchain,
        poolAddress,
        balance,
      }) => {
        state.totalStackedInPoolWithMultipliers = {
          ...state.totalStackedInPoolWithMultipliers,
          [blockchain]: {
            ...state.totalStackedInPoolWithMultipliers[blockchain],
            [poolAddress]: balance,
          },
        }
      },
      setZeroRewardBalances: (state) => {
        state.rewardBalanceByPool = zeroBalancesByBlockchain()
      },
      setZeroExpirationTimes: (state) => {
        state.expirationTimeByPool = zeroBalancesByBlockchain()
      },
      setBalance: (state, {
        blockchain,
        erc20Address,
        balance,
      }) => {
        state.balances = {
          ...state.balances,
          [blockchain]: {
            ...state.balances[blockchain],
            [erc20Address]: balance,
          },
        }
      },
      setUserLocksByPool: (state, {
        blockchain,
        poolAddress,
        lock,
      }) => {
        state.userLocksByPool = {
          ...state.userLocksByPool,
          [blockchain]: {
            ...state.userLocksByPool[blockchain],
            [poolAddress]: lock,
          },
        }
      },
      setStackedBalanceByPool: (state, {
        blockchain,
        poolAddress,
        balance,
      }) => {
        state.stackedBalancesByPool = {
          ...state.stackedBalancesByPool,
          [blockchain]: {
            ...state.stackedBalancesByPool[blockchain],
            [poolAddress]: balance,
          },
        }
      },
      setRewardBalanceByPool: (state, {
        blockchain,
        poolAddress,
        balance,
      }) => {
        state.rewardBalanceByPool = {
          ...state.rewardBalanceByPool,
          [blockchain]: {
            ...state.rewardBalanceByPool[blockchain],
            [poolAddress]: balance,
          },
        }
      },
      setExpirationTimeByPool: (state, {
        blockchain,
        poolAddress,
        expirationTime,
      }) => {
        state.expirationTimeByPool = {
          ...state.expirationTimeByPool,
          [blockchain]: {
            ...state.expirationTimeByPool[blockchain],
            [poolAddress]: expirationTime,
          },
        }
      },
    },
    actions: {
      loadPoolsInfo: async ({ state, commit, getters, dispatch }) => {
        const totalStackedInPools = await Promise.all(POOLS.map(async (pool, index) => {
          const totalStackedBase = await totalStakedInPool({
            blockchain: pool.blockchain,
            contractAddress: pool.address,
          })
          if (pool.isLpPool) {
            const _totalStacked = await totalStakedInPool({
              blockchain: pool.blockchain,
              contractAddress: pool.address,
            })
            const _totalSupply = await totalSupply({
              blockchain: pool.blockchain,
              contractAddress: pool.erc20AddressDeposit,
            })

            const {
              _reserve0,
              _reserve1,
            } = await getReserves({
              blockchain: pool.blockchain,
              contractAddress: pool.erc20AddressDeposit,
            })
            const part = _totalSupply === '0' ? new BigNumber('0') : new BigNumber(_totalStacked).dividedBy(_totalSupply)
            const amountReserve0 = part.multipliedBy(_reserve0).toFixed(0)
            const amountReserve1 = part.multipliedBy(_reserve1).toFixed(0)
            return {
              lp: totalStackedBase,
              [pool.lpAsset0Address]: amountReserve0,
              [pool.lpAsset1Address]: amountReserve1,
            }
          } else {
            return totalStackedBase
          }
        }))
        const totalStackedInPoolsV2 = await Promise.all(POOLS_V2.map(async (pool, index) => {
          const totalStackedBase = await totalStakedInPoolV2({
            blockchain: pool.blockchain,
            contractAddress: pool.address,
          })
          return totalStackedBase
        }))

        POOLS.forEach((pool, index) => {
          commit('setTotalStackedInPool', {
            blockchain: pool.blockchain,
            poolAddress: pool.address,
            balance: totalStackedInPools[index],
          })
        })
        POOLS_V2.forEach((pool, index) => {
          commit('setTotalStackedInPool', {
            blockchain: pool.blockchain,
            poolAddress: pool.address,
            balance: totalStackedInPoolsV2[index],
          })
        })
        dispatch('loadExpirationTimesV2')
      },
      loadBalances: async (
        {
          state,
          commit,
          getters,
          dispatch,
        }, {
          reset = false,
          withPoolsInfo = true,
        },
      ) => {
        if (getters.isActiveWallet) {
          if (reset && state.intervalBalances) {
            clearInterval(state.intervalBalances)
            commit('setIntervalBalances', setInterval(() => dispatch('loadBalances', {}), 30000))
          }
          const balances = await Promise.all(CURRENCIES.map(currency => getBalance({
            blockchain: currency.blockchain,
            contractAddress: currency.address,
            address: state.walletAddress,
          })))
          CURRENCIES.forEach((currency, index) => {
            commit('setBalance', {
              blockchain: currency.blockchain,
              erc20Address: currency.address,
              balance: balances[index],
            })
          })
          const locks = await Promise.all(POOLS.map(pool => userLock({
            blockchain: pool.blockchain,
            contractAddress: pool.address,
            userAddress: state.walletAddress,
          })))
          POOLS.forEach((pool, index) => {
            commit('setUserLocksByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              lock: locks[index],
            })
          })
          if (withPoolsInfo) {
            const totalStackedInPools = await Promise.all(POOLS.map(async (pool, index) => {
              const totalStackedBase = await totalStakedInPool({
                blockchain: pool.blockchain,
                contractAddress: pool.address,
              })
              if (pool.isLpPool) {
                const _totalStacked = await totalStakedInPool({
                  blockchain: pool.blockchain,
                  contractAddress: pool.address,
                })
                const _totalSupply = await totalSupply({
                  blockchain: pool.blockchain,
                  contractAddress: pool.erc20AddressDeposit,
                })
                const {
                  _reserve0,
                  _reserve1,
                } = await getReserves({
                  blockchain: pool.blockchain,
                  contractAddress: pool.erc20AddressDeposit,
                })
                const part = _totalSupply === '0' ? new BigNumber('0') : new BigNumber(_totalStacked).dividedBy(_totalSupply)
                const amountReserve0 = part.multipliedBy(_reserve0).toFixed(0)
                const amountReserve1 = part.multipliedBy(_reserve1).toFixed(0)
                return {
                  lp: totalStackedBase,
                  [pool.lpAsset0Address]: amountReserve0,
                  [pool.lpAsset1Address]: amountReserve1,
                }
              } else {
                return totalStackedBase
              }
            }))

            POOLS.forEach((pool, index) => {
              commit('setTotalStackedInPool', {
                blockchain: pool.blockchain,
                poolAddress: pool.address,
                balance: totalStackedInPools[index],
              })
            })
          }

          const totalStackedInPoolsWithMultipliers = await Promise.all(POOLS.map((pool, index) => {
            return locks[index] !== NULL_LOCK
              ? totalStakedInPoolWithMultipliers({
                blockchain: pool.blockchain,
                contractAddress: pool.address,
              })
              : new Promise((resolve) => resolve('0'))
          }))

          POOLS.forEach((pool, index) => {
            commit('setTotalStackedInPoolWithMultipliers', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              balance: totalStackedInPoolsWithMultipliers[index],
            })
          })

          const poolBalances = await Promise.all(POOLS.map((pool, index) => {
            return locks[index] !== NULL_LOCK
              ? userStacked({
                blockchain: pool.blockchain,
                contractAddress: pool.address,
                userAddress: state.walletAddress,
              })
              : new Promise((resolve) => resolve('0'))
          }))

          POOLS.forEach((pool, index) => {
            commit('setStackedBalanceByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              balance: poolBalances[index],
            })
          })

          const poolBalancesV2 = await Promise.all(POOLS_V2.map((pool, index) => {
            return userStacked({
              blockchain: pool.blockchain,
              contractAddress: pool.address,
              userAddress: state.walletAddress,
            })
          }))

          POOLS_V2.forEach((pool, index) => {
            commit('setStackedBalanceByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              balance: poolBalancesV2[index],
            })
          })

          const rewardBalances = await Promise.all(POOLS.map((pool, index) => {
            return locks[index] !== NULL_LOCK
              ? getReward({
                blockchain: pool.blockchain,
                contractAddress: pool.address,
                userAddress: state.walletAddress,
              })
              : new Promise((resolve) => resolve('0'))
          }))

          POOLS.forEach((pool, index) => {
            commit('setRewardBalanceByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              balance: rewardBalances?.[index],
            })
          })

          const rewardBalancesV2 = await Promise.all(POOLS_V2.map((pool, index) => {
            return getReward({
              blockchain: pool.blockchain,
              contractAddress: pool.address,
              userAddress: state.walletAddress,
            })
          }))

          POOLS_V2.forEach((pool, index) => {
            commit('setRewardBalanceByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              balance: rewardBalancesV2?.[index],
            })
          })

          const expirationTimes = await Promise.all(POOLS.map((pool, index) => {
            return locks[index] !== NULL_LOCK
              ? expirationDeposit({
                blockchain: pool.blockchain,
                contractAddress: pool.address,
                userAddress: state.walletAddress,
              })
              : new Promise((resolve) => resolve(null))
          }))

          POOLS.forEach((pool, index) => {
            commit('setExpirationTimeByPool', {
              blockchain: pool.blockchain,
              poolAddress: pool.address,
              expirationTime: expirationTimes?.[index],
            })
          })

          dispatch('loadExpirationTimesV2')
        }
      },
      loadExpirationTimesV2: async ({ commit }) => {
        const expirationTimesV2 = await Promise.all(POOLS_V2.map((pool, index) => {
          return expirationDepositV2({
            blockchain: pool.blockchain,
            contractAddress: pool.address
          })
        }))

        POOLS_V2.forEach((pool, index) => {
          commit('setExpirationTimeByPool', {
            blockchain: pool.blockchain,
            poolAddress: pool.address,
            expirationTime: expirationTimesV2?.[index]
          })
        })
      },
      loadPoolInfo: async ({ commit }) => {
        const totalStackedInPools = await Promise.all(POOLS.map(async (pool, index) => {
          const totalStackedBase = await totalStakedInPool({
            blockchain: pool.blockchain,
            contractAddress: pool.address,
          })
          if (pool.isLpPool) {
            const _totalStacked = await totalStakedInPool({
              blockchain: pool.blockchain,
              contractAddress: pool.address,
            })
            const _totalSupply = await totalSupply({
              blockchain: pool.blockchain,
              contractAddress: pool.erc20AddressDeposit,
            })
            const {
              _reserve0,
              _reserve1,
            } = await getReserves({
              blockchain: pool.blockchain,
              contractAddress: pool.erc20AddressDeposit,
            })
            const part = _totalSupply === '0' ? new BigNumber('0') : new BigNumber(_totalStacked).dividedBy(_totalSupply)
            const amountReserve0 = part.multipliedBy(_reserve0).toFixed(0)
            const amountReserve1 = part.multipliedBy(_reserve1).toFixed(0)
            return {
              lp: totalStackedBase,
              [pool.lpAsset0Address]: amountReserve0,
              [pool.lpAsset1Address]: amountReserve1,
            }
          } else {
            return await totalStakedInPool({
              blockchain: pool.blockchain,
              contractAddress: pool.address,
            })
          }
        }))

        POOLS.forEach((pool, index) => {
          commit('setTotalStackedInPool', {
            blockchain: pool.blockchain,
            poolAddress: pool.address,
            balance: totalStackedInPools[index],
          })
        })
      },
      init: async ({ state, commit, dispatch }) => {
        await dispatch('loadPoolsInfo')
        if (state.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT) {
          if (state.walletConnected && state.userSelectedWallet) {
            await dispatch('connect', { wallet: state.userSelectedWallet, network: BLOCKCHAIN_ETHEREUM })
          } else {
            commit('setUserSelectedWallet', null)
            commit('setWalletConnected', false)
          }
        }
        const active = isMetamaskInstalled()
        if (state.userSelectedWallet === WEB3_PROVIDER_METAMASK && active && state.walletConnected) {
          if (active) {
            try {
              await dispatch('connectToMetamask')
              const address = await getAddressMetamask()
              const chainId = await getChainIdMetamask()
              if (address && chainId) {
                commit('setWalletConnected', true)
                commit('setWalletAddress', address)
                commit('setWalletChainIds', [chainId])
                if (!state?.walletChainIds || !state.walletChainIds.includes(chainId)) {
                  await addChain(chainId)
                }
                await dispatch('loadBalances', { withPoolsInfo: false })
                commit('setIntervalBalances', setInterval(() => dispatch('loadBalances', {}), BALANCES_UPDATE_INTERVAL))
              }
            } catch (err) {
              console.error(err)
              await dispatch('loadPoolInfo')
            }
          } else {
            await dispatch('loadPoolInfo')
          }
        } else {
          await dispatch('loadPoolInfo')
        }
      },
      connectToMetamask: async ({ state, commit, dispatch }) => {
        const addressListener = async (addresses) => {
          await commit('setWalletAddress', addresses[0])
          await commit('setZeroBalances')
          await dispatch('loadBalances', { reset: true })
        }
        const chainIdListener = async (chainId) => {
          commit('setWalletChainIds', [chainId])
          await commit('setZeroBalances')
          await dispatch('loadBalances', { reset: true })
        }
        const connectListener = async () => {
          await commit('setWalletConnected', true)
          await commit('setZeroBalances')
          await dispatch('loadBalances', { reset: true })
        }
        const disconnectListener = async () => {
          await commit('setWalletConnected', false)
          await commit('setZeroBalances')
        }
        eventEmitterMetamask.on('accountsChanged', addressListener)
        eventEmitterMetamask.on('chainChanged', chainIdListener)
        eventEmitterMetamask.on('connect', connectListener)
        eventEmitterMetamask.on('disconnect', disconnectListener)
        eventEmitterMetamask.on('start', connectListener)
        eventEmitterMetamask.on('stop', disconnectListener)
        const address = await getAddressMetamask()
        const chainId = await getChainIdMetamask()
        if (address && chainId) {
          commit('setWalletConnected', true)
          commit('setWalletAddress', address)
          commit('setWalletChainIds', [chainId])
        }
      },
      connectToWalletConnect: async ({
        state,
        commit,
        dispatch
      }, chainIds) => {
        const provider = chainIds ? await getProviderAsync(true) : await getProviderAsync()
        try {
          const walletUriListener = async (uri) => {
            await commit('setWalletConnectUri', uri)
            await dispatch('showQrCodeWalletconnect')
          }
          const addressListener = async (addresses) => {
            await commit('setWalletAddress', addresses[0])
            await commit('setZeroBalances')
            await dispatch('loadBalances', { reset: true })
          }
          const chainIdListener = async (chainIds) => {
            await commit('setWalletChainIds', chainIds)
            await commit('setZeroBalances')
            await dispatch('loadBalances', { reset: true })
          }
          const connectListener = async () => {
            await dispatch('ui/closeModal', state.walletConnectModalId, { root: true })
            await commit('setWalletConnected', true)
            await commit('setZeroBalances')
            await dispatch('loadBalances', { reset: true })
          }
          const disconnectListener = async () => {
            await dispatch('ui/closeModal', state.walletConnectModalId, { root: true })
            await commit('setWalletConnected', false)
            await dispatch('disconnectWallet')
          }
          const setWalletConnectError = async (text) => {
            await dispatch('ui/updateModalParams',
              {
                id: state.walletConnectModalId,
                payload: { props: { error: text } }
              },
              { root: true }
            )
          }
          eventEmitterWalletConnect.on('walletUri', walletUriListener)
          eventEmitterWalletConnect.on('accountsChanged', addressListener)
          eventEmitterWalletConnect.on('chainChanged', chainIdListener)
          eventEmitterWalletConnect.on('connect', connectListener)
          eventEmitterWalletConnect.on('disconnect', disconnectListener)
          eventEmitterWalletConnect.on('start', connectListener)
          eventEmitterWalletConnect.on('stop', disconnectListener)
          eventEmitterWalletConnect.on('setWalletConnectError', setWalletConnectError)
          if (provider && !getSession()) {
            await sessionConnect(chainIds)
            const accounts = await provider.enable()
            eventEmitterWalletConnect.emit('accountsChanged', accounts)
          }
          const { address, listChainIds } = await getChainsAndAddressWalletConnect()
          if (address && listChainIds) {
            commit('setWalletConnected', true)
            commit('setWalletAddress', address)
            commit('setWalletChainIds', listChainIds)
          }
        } catch (err) {
          if (provider && state.walletConnected) {
            await provider.disconnect()
          }
          console.error(err)
          eventEmitterWalletConnect.emit('setWalletConnectError', err?.message ?? err)
        }
      },
      connect: async (
        {
          state,
          commit,
          dispatch,
        }, {
          network,
          networks,
          wallet,
        },
      ) => {
        commit('setUserSelectedWallet', wallet)
        if (wallet === WEB3_PROVIDER_METAMASK) {
          const active = isMetamaskInstalled()
          if (active) {
            await dispatch('connectToMetamask')
            const address = await getAddressMetamask()
            const chainId = await getChainIdMetamask()
            if (address && chainId) {
              await dispatch('loadBalances', { reset: true })
              commit('setIntervalBalances', setInterval(() => dispatch('loadBalances', {}), BALANCES_UPDATE_INTERVAL))
            }
          }
        }
        if (wallet === WEB3_PROVIDER_WALLETCONNECT) {
          await dispatch('connectToWalletConnect', networks)
          const { address, listChainIds } = await getChainsAndAddressWalletConnect()
          if (address && listChainIds) {
            await dispatch('loadBalances', { reset: true })
            commit('setIntervalBalances', setInterval(() => dispatch('loadBalances', {}), BALANCES_UPDATE_INTERVAL))
          }
        }
      },
      disconnectWallet: async ({ state, commit, dispatch }) => {
        try {
          if (state.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT) {
            const provider = await getProviderAsync()
            if (state.walletConnected) {
              await provider.disconnect()
            }
            await resetSession({ hardReset: true })
          }
        } catch (err) {
          console.error('Error disconnectWallet', err)
        }
        commit('setZeroBalances')
        commit('setZeroUserLocks')
        commit('setZeroTotalStackedInPool')
        commit('setZeroTotalStackedInPoolWithMultipliers')
        commit('setZeroStackedBalances')
        commit('setZeroRewardBalances')
        commit('setZeroExpirationTimes')
        commit('setUserSelectedWallet', null)
        commit('setWalletConnected', false)
        dispatch('loadExpirationTimesV2')
        commit('setWalletAddress', null)
        commit('setWalletChainIds', null)
      },
      showQrCodeWalletconnect: async ({ state, commit, dispatch }) => {
        if (state.walletConnectUri) {
          const modalId = await dispatch('ui/openModal', {
            component: () => WalletconnectQr,
            props: {
              uri: state.walletConnectUri,
              error: state.walletConnectError
            }
          }, { root: true })
          await commit('setWalletConnectModalId', modalId)
        }
      },
    },
  })
}
