export default {
  // min: 0,
  // max: 1,
  // interval: 0.05,
  eventType: 'auto',
  width: '100%',
  height: 4,
  dotSize: 16,
  speed: 0.7,
  disabled: false,
  data: null,
  clickable: true,
}
