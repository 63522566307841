import { mapActions, mapMutations, mapState } from 'vuex'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_POLYGON,
  WEB3_PROVIDER_METAMASK,
  WEB3_PROVIDER_WALLETCONNECT,
} from '@/constants/blockchain'

export default {
  props: {
    onChange: Function,
  },
  computed: {
    ...mapState({
      userSelectedWallet: (state) => state.network.userSelectedWallet,
      walletChainIds: (state) => state.network.walletChainIds,
      walletAddress: (state) => state.network.walletAddress,
    }),
    clippedAddress () {
      if (!this.walletAddress) return ''
      return `${this.walletAddress.slice(0, 6)}...${this.walletAddress.slice(-4)}`
    },
    metamaskSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_METAMASK
    },
    walletConnectSelected () {
      return this.userSelectedWallet === WEB3_PROVIDER_WALLETCONNECT
    },
    binanceSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_BINANCE)
    },
    ethereumSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_ETHEREUM)
    },
    polygonSelected () {
      return this?.walletChainIds?.includes(BLOCKCHAIN_POLYGON)
    },
    linkToEtherscan () {
      return `${process.env.VUE_APP_EXPLORER_ETHEREUM}/address/${this.walletAddress}`
    },
    linkToBscscan () {
      return `${process.env.VUE_APP_EXPLORER_BINANCE}/address/${this.walletAddress}`
    },
    linkToPolygon () {
      return `${process.env.VUE_APP_EXPLORER_POLYGON}/address/${this.walletAddress}`
    },
  },
  data () {
    return {
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      WEB3_PROVIDER_METAMASK,
      WEB3_PROVIDER_WALLETCONNECT,
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'ui/closeModal',
      connectToWallet: 'network/connect',
      disconnectWallet: 'network/disconnectWallet',
    }),
    ...mapMutations({
    }),
    async disconnect () {
      await this.disconnectWallet()
      this.closeModal()
    },
    connect (wallet = WEB3_PROVIDER_METAMASK) {
      this.connectToWallet({
        wallet,
        network: this.blockchain,
      })
      this.closeModal()
    },
    copy () {
      const textArea = document.createElement('textarea')
      textArea.value = this.walletAddress
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
    },
  },
}
