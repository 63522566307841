import BigNumber from 'bignumber.js'
import { mapState, mapGetters } from 'vuex'
import { format } from '@/utils/moneyFormat'
import { getRewardInfo } from '@/api/crm'
import Skeleton from '@/components/Skeleton/Skeleton.vue'

export default {
  components: {
    Skeleton
  },
  data () {
    return {
      loading: true,
      stakers: 850,
      rewards: null,
      skeletonRewards: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
  async created () {
    this.rewards = (await getRewardInfo()).listWeeklyRewards
  },
  computed: {
    ...mapState({
      timePrice: state => state.timePrice,
      ethPrice: state => state.ethPrice,
      bnbPrice: state => state.bnbPrice,
    }),
    ...mapGetters({
      totalApy: 'totalApy',
      totalStakedInTime: 'network/totalStakedInTime',
      totalStakedInEth: 'network/totalStakedInEth',
      totalStakedInBnb: 'network/totalStakedInBnb',
      rewardApy: 'rewardApy',
    }),
    poolRewardApy () {
      return new BigNumber(this.rewardApy).toFixed(2)
    },
    totalStakedInTimeFormatted () {
      if (this.totalStakedInTime === null) {
        return 0
      }
      return format(this.totalStakedInTime, {
        toFixedNumber: 0,
      })
    },
    totalStakedInEthFormatted () {
      if (this.totalStakedInEth === null) {
        return 0
      }
      return format(this.totalStakedInEth, {
        toFixedNumber: 2,
      })
    },
    totalStakedInBnbFormatted () {
      if (this.totalStakedInBnb === null) {
        return 0
      }
      return format(this.totalStakedInBnb, {
        toFixedNumber: 2,
      })
    },
    stakedUsd () {
      if (+this.totalStakedInTime === 0 || this.timePrice === null) {
        return 0
      }
      const timeUsd = new BigNumber(this.totalStakedInTime).multipliedBy(this.timePrice)
      const ethUsd = new BigNumber(this.totalStakedInEth).multipliedBy(this.ethPrice)
      const bnbUsd = new BigNumber(this.totalStakedInBnb).multipliedBy(this.bnbPrice)
      return format(timeUsd.plus(ethUsd).plus(bnbUsd), {
        toFixedNumber: 0,
      })
    },
    totalRewardsInTime () {
      const totalRewards = this.rewards.reduce((totalRewards, reward) => totalRewards.plus(reward.time), new BigNumber(0))
      return format(totalRewards, {
        toFixedNumber: 0
      })
    },
    totalRewardsInUsd () {
      const totalRewards = this.rewards.reduce((totalRewards, reward) => totalRewards.plus(reward.usd), new BigNumber(0))
      return format(totalRewards, {
        toFixedNumber: 0
      })
    },
  },
  methods: {
    rewardFormatted (value) {
      return format(value, {
        toFixedNumber: 2
      })
    },
  },
}
