import axios from 'axios'
import _ from 'lodash'

export async function getRewardInfo () {
  try {
    const result = await axios.get(`${process.env.VUE_APP_CRM_URL}/api/v1/stacking/reward-info-time`)
    return _.get(result, 'data.data')
  } catch (err) {
    console.error('REWARD_INFO_ERROR', err)
    return null
  }
}
