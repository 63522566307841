import { mapActions } from 'vuex'
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHEREUM, WEB3_PROVIDER_METAMASK, WEB3_PROVIDER_WALLETCONNECT } from '@/constants/blockchain'
import SelectNetwork from '@/modals/SelectNetwork/SelectNetwork.vue'

export default {
  computed: {
  },
  data () {
    return {
      blockchain: BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_ETHEREUM,
      BLOCKCHAIN_BINANCE,
      WEB3_PROVIDER_METAMASK,
      WEB3_PROVIDER_WALLETCONNECT,
    }
  },
  methods: {
    ...mapActions({
      connectToWallet: 'network/connect',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
    }),
    async connect (wallet = WEB3_PROVIDER_METAMASK) {
      if (wallet === WEB3_PROVIDER_METAMASK) {
        this.connectToWallet({ wallet, networks: [this.blockchain] })
        this.closeModal()
      }
      if (wallet === WEB3_PROVIDER_WALLETCONNECT) {
        this.openModal({
          component: () => SelectNetwork,
          props: {
            onSelectNetworkCb: (networks) => {
              this.connectToWallet({ wallet, networks })
              this.closeModal()
            }
          }
        })
      }
    },
    setBlockchain (blockchain) {
      this.blockchain = blockchain
    }
  },
}
