import axios from 'axios'
import BigNumber from 'bignumber.js'
import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'
import ui from './ui'
import network from './network'

const LX_BACKEND = axios.create({
  baseURL: 'https://api.laborx.com/',
  timeout: 6000,
})

const COINGECKO_API = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3/',
  timeout: 6000,
})

const dataState = createPersistedState({
  paths: [
    'network.userSelectedWallet',
    'network.walletConnected',
    'ui.showOnlyStackedPools',
  ],
})

export default createStore({
  strict: false,
  state: {
    currencyPrices: {},
    timePrice: null,
    ethPrice: null,
    bnbPrice: null,
    maticPrice: null,
    userLabels: [],
    userData: [],
    gigsLabels: [],
    gigsData: [],
  },
  getters: {
    rewardApy () {
      return 14.11
    },
    priceApy (state) {
      if (state.timePrice === null) {
        return null
      }
      return new BigNumber(state.timePrice).dividedBy(12).multipliedBy(100).minus(100).toFixed(2)
    },
    totalApy (state, getters) {
      if (getters.priceApy === null) {
        return null
      }
      return new BigNumber(getters.rewardApy).multipliedBy(1.2).dividedBy(100)
        .plus(1).multipliedBy(new BigNumber(state.timePrice).toFixed(2)).dividedBy(12).minus(1).multipliedBy(100).toFixed(0)
    },
  },
  mutations: {
    setTimePrice (state, price) {
      state.timePrice = price
    },
    setEthPrice (state, price) {
      state.ethPrice = price
    },
    setBnbPrice (state, price) {
      state.bnbPrice = price
    },
    setMaticPrice (state, price) {
      state.maticPrice = price
    },
    setCurrencyPrice (state, {
      currencyName,
      price,
    }) {
      state.currencyPrices = {
        ...state.currencyPrices,
        [currencyName]: price,
      }
    },
    setUserLabels (state, userLabels) {
      state.userLabels = userLabels
    },
    setUserData (state, userData) {
      state.userData = userData
    },
    setGigsLabels (state, gigsLabels) {
      state.gigsLabels = gigsLabels
    },
    setGigsData (state, gigsData) {
      state.gigsData = gigsData
    },
  },
  actions: {
    async loadRate ({ commit }) {
      try {
        const { data } = await LX_BACKEND.get('rates', {
          params: { currency: 'TIME,ETH,BNB' },
        })
        const priceTime = data.result?.find(item => item.currency === 'TIME')?.price
        const priceEth = data.result?.find(item => item.currency === 'ETH')?.price
        const priceBnb = data.result?.find(item => item.currency === 'BNB')?.price
        commit('setTimePrice', priceTime)
        commit('setEthPrice', priceEth)
        commit('setBnbPrice', priceBnb)
        commit('setCurrencyPrice', {
          currencyName: 'TIME',
          price: priceTime,
        })
        commit('setCurrencyPrice', {
          currencyName: 'ETH',
          price: priceEth,
        })
        commit('setCurrencyPrice', {
          currencyName: 'BNB',
          price: priceBnb,
        })
      } catch (e) {
        console.error(e)
        const { data } = await COINGECKO_API.get('/simple/price', {
          params: {
            ids: 'chronobank,binancecoin,ethereum,matic-network,crypto-gaming-united',
            vs_currencies: 'usd',
          },
        })
        const priceTime = data?.chronobank?.usd || 0
        const priceEth = data?.ethereum?.usd || 0
        const priceBnb = data?.binancecoin?.usd || 0
        commit('setTimePrice', priceTime)
        commit('setEthPrice', priceEth)
        commit('setBnbPrice', priceBnb)
        commit('setCurrencyPrice', {
          currencyName: 'TIME',
          price: priceTime,
        })
        commit('setCurrencyPrice', {
          currencyName: 'ETH',
          price: priceEth,
        })
        commit('setCurrencyPrice', {
          currencyName: 'BNB',
          price: priceBnb,
        })
      }
    },
    async loadStat ({ commit }) {
      try {
        const { data } = await LX_BACKEND.get('timewarp/statistics/users-and-gigs')

        let result = prepareData(data.result.users)
        commit('setUserLabels', result.labels)
        commit('setUserData', result.data)

        result = prepareData(data.result.gigs)
        commit('setGigsLabels', result.labels)
        commit('setGigsData', result.data)
      } catch (e) {
        console.error(e)
      }
    },
    async subscribe (store, email) {
      try {
        await LX_BACKEND.post('subscribers/subscribe', {
          payload: {
            site_id: 'timewarp.finance',
            email,
          },
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
  modules: {
    ui: ui(),
    network: network(),
  },
  plugins: [dataState],
})

function prepareData (stats) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ]
  const prepareData = {}
  const todayPeriod = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`
  const todayDay = new Date().getDate()
  for (const stat of stats) {
    const key = stat.date.substring(0, 7)
    if (key === todayPeriod && todayDay < 10) {
      continue
    }
    if (prepareData[key]) {
      prepareData[key] += +stat.count
    } else {
      prepareData[key] = +stat.count
    }
  }
  const labels = []
  const data = []
  let sum = 0
  for (const period in prepareData) {
    const key = monthNames[+period.substring(5, 7) - 1] + ' ' + period.substring(0, 4)
    labels.push(key)
    data.push(prepareData[period] + sum)
    sum += prepareData[period]
  }
  return {
    labels,
    data,
  }
}
