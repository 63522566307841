<div class="container faq-container">
  <div class="header">FAQ</div>
  <div class="faq">
    <div class="faq-block" :class="{ active: showed.indexOf(1) !== -1 }">
      <div class="question" @click="onClick(1)">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>What is TimeWarp?</span>
      </div>
      <transition name="fade" :duration="{ enter: 200, leave: 1 }">
        <div v-if="showed.indexOf(1) !== -1" class="answer">
          TimeWarp is a staking programme for TIME, the native token of the Chrono.tech ecosystem.
          By locking TIME tokens in the TimeWarp smart contract, you’ll receive regular rewards.
        </div>
      </transition>
    </div>
    <div class="faq-block" :class="{ active: showed.indexOf(2) !== -1 }">
      <div class="question" @click="onClick(2)">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>How can I store TIME?</span>
      </div>
      <transition name="fade" :duration="{ enter: 200, leave: 1 }">
        <div v-if="showed.indexOf(2) !== -1" class="answer">
          TIME is an ERC20 token and can be held in any regular Ethereum wallet. To lock TIME in the TimeWarp smart
          contract, you’ll also need a wallet capable of interacting with decentralised applications – initially MetaMask.
        </div>
      </transition>
    </div>
    <div class="faq-block" :class="{ active: showed.indexOf(3) !== -1 }">
      <div class="question" @click="onClick(3)">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Where can I buy TIME?</span>
      </div>
      <transition name="fade" :duration="{ enter: 200, leave: 1 }">
        <div v-if="showed.indexOf(3) !== -1" class="answer">
          Time can be bought on <a href="https://timex.io/buy-time" target="_blank">TimeX</a>,
          <a href="https://trade.kucoin.com/TIME-BTC" target="_blank">KuCoin</a>,
          <a href="https://app.uniswap.org/#/swap?outputCurrency=0x485d17a6f1b8780392d53d64751824253011a260&inputCurrency=ETH&use=V2" target="_blank">Uniswap</a>,
          <a href="https://pancakeswap.finance/swap?outputCurrency=0x3b198e26E473b8faB2085b37978e36c9DE5D7f68" target="_blank">PancakeSwap</a>,
          and <a href="https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xb970bc1bd4fcd639c37aa8efd6713eadc577252f" target="_blank">QuickSwap</a>.
        </div>
      </transition>
    </div>
    <div class="faq-block" :class="{ active: showed.indexOf(4) !== -1 }">
      <div class="question" @click="onClick(4)">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>How many TIME tokens are there?</span>
      </div>
      <transition name="fade" :duration="{ enter: 200, leave: 1 }">
        <div v-if="showed.indexOf(4) !== -1" class="answer">
          There are a total of <a href="https://coinmarketcap.com/currencies/chrono-tech/">710,113 TIME</a> tokens –
          far less than the supply of tokens for most other major blockchain platforms.
        </div>
      </transition>
    </div>
    <div class="faq-block" :class="{ active: showed.indexOf(5) !== -1 }">
      <div class="question" @click="onClick(5)">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>How will staking rewards be distributed?</span>
      </div>
      <transition name="fade" :duration="{ enter: 200, leave: 1 }">
        <div v-if="showed.indexOf(5) !== -1" class="answer">
          TIME tokens will be bought from the open market using a proportion of revenues generated by services in
          the Chrono.tech ecosystem. These tokens will be held in a designated wallet, and distributed weekly to users
          who lock their TIME in a smart contract. Users will receive rewards in proportion to the amount of TIME they
          lock, and a multiple determined by how long they commit to locking tokens.
        </div>
      </transition>
    </div>
  </div>
</div>
