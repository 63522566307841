<div class="pool">
  <div class="top-block">
    <div class="pool-icon">
      <img :src="pool.icon" alt="Pool Icon" />
    </div>
    <div class="pools-info">
      <div class="stat pool-name">
        <div class="label">Pool</div>
        <div class="value">
          {{ pool.name }}
          <a v-if="pool.active" class="contract" :href="contractLink" target="_blank" rel="noopener">
            <span>Contract</span>
            <tw-icon class="blank" icon="blank" />
          </a>
        </div>
      </div>
      <div class="stat pool-apy">
        <div class="label">
          <span>APY</span>
          <tw-icon v-if="apyText" class="icon" icon="info" v-popover="apyText" />
        </div>
        <template v-if="pool.active">
          <tw-loader v-if="totalStackedInPool === null" class="loader" />
          <div v-else class="value">{{ poolRewardApy }}%</div>
        </template>
        <div v-else class="value">n/a</div>
      </div>
      <div class="stat pool-tvl">
        <div class="label">TVL</div>
        <div v-if="!isLpPool" class="value">
          <template v-if="pool.active">
            <tw-loader v-if="totalStackedInPool === null" class="loader" />
            <template v-else>{{ tvlFormatted }} {{ pool.name }} <span class="usd">${{ tvlUsd }}</span></template>
          </template>
          <div v-else class="value">n/a</div>
        </div>
        <template v-else>
          <div class="value">
            <template v-if="pool.active">
              <tw-loader v-if="totalStackedInPool === null" class="loader" />
              <template v-else>
                <div>{{ tvlFormattedAsset0 }} {{ currencyAsset0.name }} <span class="usd">${{ tvlAsset0Usd }}</span></div>
                <div>{{ tvlFormattedAsset1 }} {{ currencyAsset1.name }} <span class="usd">${{ tvlAsset1Usd }}</span></div>
              </template>
            </template>
            <div v-else class="value">n/a</div>
          </div>
        </template>

      </div>
      <div class="stat pool-actions" :class="{ inactive: !pool.active }">
        <div class="calculator-empty"></div>
        <tw-button @click="toggleStacking" :disabled="!pool.active" class="button" :class="{ active: open }">
          <div
            v-if="pool.active"
            class="icon-btn"
          >
            Staking
            <tw-icon class="arrow" icon="arrow" />
          </div>
          <template v-else>Coming Soon</template>
        </tw-button>
      </div>
    </div>
  </div>
  <div v-if="open" class="bottom-block">
    <div class="action">
      <div class="label">Wallet Balance</div>
      <div class="value">{{ balanceFormatted }} {{currencyDeposit.name}}</div>
      <div class="buttons two">
        <tw-button class="button button-transparent" @click="stake" :disabled="stakeDisabled">Deposit</tw-button>
        <tw-button
          :href="currencyDeposit.getLink"
          @click="onGetLpClick"
          target="_blank" rel="noopener" class="button">Get {{ currencyDeposit.name }}</tw-button>
      </div>
    </div>
    <div class="action">
      <div class="label">Already Staked</div>
      <div class="value">{{ stackedBalanceFormatted }} {{currencyDeposit.name}}</div>
      <div class="buttons">
        <tw-button class="button button-transparent" @click="withdraw" :disabled="!withdrawEnabled">Withdraw</tw-button>
      </div>
    </div>
    <div class="action">
      <div class="label">Rewards</div>
      <div class="value">{{ rewardBalanceFormatted }} TIME</div>
      <div class="buttons two">
        <tw-button class="button button-transparent" @click="harvest" :disabled="harvestDisabled">Harvest</tw-button>
        <tw-button v-if="!isLpPool" class="button button-transparent" @click="compound" :disabled="compoundDisabled">
          Compound
        </tw-button>
      </div>
    </div>

    <div class="action">
      <template v-if="userLock !== NULL_LOCK">
        <div class="label">Staking Info</div>
        <div class="value">
          <span class="value-label">End Lock: </span>
          <tw-loader v-if="!expirationTime" class="loader" />
          <template v-else>{{ expirationTimeFormatted }}</template>
        </div>
        <div class="value">
          <span class="value-label">Lock Period: </span>
          {{ LOCK_PERIOD_NAMES[userLock] }}
        </div>
        <div class="value">
          <span class="value-label">Multiplier: </span>
          <span>{{LOCK_MULTIPLIERS?.[userLock]?.text}}</span>
        </div>
        <div class="value">
          <span class="value-label">Pool Share: </span>
          <tw-loader v-if="totalStackedInPool === null || stackedBalance === 0" class="loader" />
          <template v-else>{{ totalShareFormatted }}</template>
        </div>
        <div class="value">
          <span class="value-label">Multiplied Pool Share: </span>
          <tw-loader v-if="totalStackedInPoolWithMultipliers === null || stackedBalance === 0" class="loader" />
          <template v-else>{{ totalShareWithMultiplierFormatted }}</template>
        </div>
      </template>
    </div>
  </div>
</div>
