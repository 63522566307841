<component
  :is="tag"
  class="tw-button"
  v-bind="$attrs"
  :loading="loading"
  :to="to"
>
  <span class="button-wrap">
    <slot></slot>
  </span>
  <span v-if="loading" class="loader-wrap">
    <loader class="loader" />
  </span>
</component>
