import * as icons from '@/components/Icon/icons'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'tw-icon',
  props: {
    icon: String,
  },
  methods: {
    iconSvgText () {
      return icons[upperFirst(camelCase(this.icon))]
    }
  }
}
