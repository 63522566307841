<tw-modal class="root connect-wallet">
  <h3 class="modal-title">Select network</h3>
  <section class="section networks-section">
    <h4 class="section-title">
      To connect to WalletConnect, you need to select a network, but some wallets are not able to automatically connect to a given network, for example, Metamask, and then you need to manually select the required network before connecting
    </h4>
    <ul>
      <li
          class="blockchain"
          @click="selectBlockchain(BLOCKCHAIN_ETHEREUM)"
          :class="selectedBlockchains[BLOCKCHAIN_ETHEREUM]"
      >
        <tw-icon class="icon eth-icon" icon="eth" />
        <span>Ethereum</span>
      </li>
      <li
          class="blockchain"
          @click="selectBlockchain(BLOCKCHAIN_BINANCE)"
          :class="selectedBlockchains[BLOCKCHAIN_BINANCE]"
      >
        <tw-icon class="icon bsc-icon" icon="bsc" />
        <span>BSC</span>
      </li>
      <li
          class="blockchain"
          @click="selectBlockchain(BLOCKCHAIN_POLYGON)"
          :class="selectedBlockchains[BLOCKCHAIN_POLYGON]"
      >
        <tw-icon class="icon polygon-icon" icon="polygon" />
        <span>Polygon</span>
      </li>
    </ul>
    <tw-button
        class="btn-confirm-blockchains"
        @click="confirmBlockchains"
        :disabled="!isSelectedBlockchain"
    >
      Confirm
    </tw-button>
  </section>
</tw-modal>
