import HeaderBlock from '@/partials/Header/Header.vue'
import Intro from './Intro/Intro.vue'
// import Timer from './Timer/Timer.vue'
import Benefits from './Benefits/Benefits.vue'
import TimeBlock from './Time/Time.vue'
import Projects from './Projects/Projects.vue'
import StackingResults from './StackingResults/StackingResults.vue'
import Subscribe from './Subscribe/Subscribe.vue'
import Faq from './FAQ/FAQ.vue'
// import Partners from './Partners/Partners.vue'
import FooterBlock from '@/partials/Footer/Footer.vue'

export default {
  name: 'Home',
  components: {
    HeaderBlock,
    Intro,
    // Timer,
    Benefits,
    TimeBlock,
    Projects,
    StackingResults,
    Subscribe,
    Faq,
    // Partners,
    FooterBlock,
  },
  beforeCreate () {
    document.title = 'TIME Staking | TimeWarp'
  },
}
